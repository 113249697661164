import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";

const MessageSmsSendList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('#sms_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#sms_month').val(parseInt(Fn.getCurrentDate().split('-')[1]));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/message/sms/send/list/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&key=${$('#key').val()}&s_result=${$('#s_result option:selected').val() || ''}&s_opt_cmp=${$('#s_opt_cmp option:selected').val() || ''}&sms_year=${$('#sms_year option:selected').val() || ''}&sms_month=${$('#sms_month option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.dstaddr");

          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8) {
            $('#s_branch_code').val(localStorage.getItem("USER.systemBranchCode"));
            $('#s_branch_code').prop("disabled", true);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const memo_form = document.getElementById('memo_form');

  function del_ok() {
    let con = 0;
    let editMsgid = "";

    for (let i = 0; i < memo_form.elements.length; i++) {
      if (memo_form.elements[i].type === 'checkbox') {
        if (memo_form.elements[i].checked === true) {
          let nn = memo_form.elements[i].name;
          let m_chk = nn.substr(0, 3);

          if (m_chk === "chk") {
            con++;
            editMsgid += memo_form.elements[i].value + "|";
          }
        }
      }
    }

    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    if (window.confirm('체크한 리스트를 삭제하시겠습니까?')) {
      memo_form.action = 'notice_del.php';
      memo_form.target = "common_i_pop";
      memo_form.submit();
    }
  }

  //  3-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    let h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  function chk_total_record() {
    const auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    const down_reason = $("#down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const tot_record = memo_form.total_record.value;
    const max_num = 10000;

    if (tot_record > max_num) {
      popUp(`send_list_pop.html?sms_year=2023&sms_month=7&s_branch_code=&s_stat=&s_opt_cmp=&s_result=&key=&max_num=${max_num}&auth_code=${auth_code}&down_reason=${down_reason}`, 'send_list_pop', '550', '250', 'yes');
    } else {
      window.location.href = `Excel_sendlist.php?sms_year=2023&sms_month=7&s_branch_code=&s_stat=&s_opt_cmp=&s_result=&key=&f_limit=0&l_limit=${tot_record}&max_num=${max_num}&excel_num=1&auth_code=${auth_code}&down_reason=${down_reason}`;
      // common_i_pop.location.href = `Excel_sendlist.php?sms_year=2023&sms_month=7&s_branch_code=&s_stat=&s_opt_cmp=&s_result=&key=&f_limit=0&l_limit=${tot_record}&max_num=${max_num}&excel_num=1`;
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="성명/회원번호/수신번호 검색" id="key" style={{width: "200px"}}/>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_result">
              <option value="">::전송결과</option>
              <option value="100">발송성공</option>
              <option value="xxx">실패</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_opt_cmp">
              <option value="">::문자타입</option>
              <option value="SMS">SMS</option>
              <option value="LMS">LMS</option>
              <option value="kakao">kakao</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="sms_year">
              <option value="">::년도</option>
              {Array.from({length: new Date().getFullYear() - 2013 + 1}, (_, index) => new Date().getFullYear() - index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="sms_month">
              <option value="">::월</option>
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th className="w_70px" style={{width: "80px"}}>No</th>
              <th>지점</th>
              <th>회원번호</th>
              <th>회원명</th>
              <th>발송일시</th>
              <th>수신번호</th>
              <th>문자타입</th>
              <th>발송결과</th>
              <th className="w-40">회원정보</th>
            </tr>
            {rets && rets.map((ret, i) => {
              return (
                <tr>
                  <td>{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - i}</td>
                  <td>{ret?.branchName || ''}</td>
                  <td>{ret?.ext_col2 || ''}</td>
                  <td>{ret?.ext_col1 || ''}</td>
                  <td>{ret?.send_time?.replaceAll("-", ".")?.replaceAll("T", " ")?.substr(2, 14) || ''}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{ret?.opt_cmp || ''}</td>
                  <td>{ret?.statTxt || ''}</td>
                  <td style={{textAlign: 'left'}}><a title={ret?.text || ''}>{ret?.text?.substr(0, 120)}</a></td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={9}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {localStorage.getItem("USER.systemCsYN") !== "Y" &&
              <>
                <div className="d-flex mx-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유를 입력해 주세요." style={{width: "280px"}}/>
                  <button type="button" className="btn btn-sm btn-outline-point px-2">엑셀저장</button>
                </div>
                {pageInfo?.totalArticles > 0 && (
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                    <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('sms_send_list')}>연락처 확인</button>
                  )
                )}
              </>
            }
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default MessageSmsSendList;
