import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Modal from 'react-modal';
import Fn from "../../libraries/Fn";
import {useHistory, useLocation} from "react-router-dom";


const LockerNumber = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [loc, setLoc] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const init = location?.state?.init || '';

  useEffect(() => {
    load();
  }, [currPage, renew]);

  // useEffect(() => {
  //   $('.div-locker').remove();
  // }, [loc]);

  const load = () => {
    $('#overlay').show();

    axios2
      .post(`/locker/locker/number/ready?init=${init}`)
      .then((response) => {
        if (init == "Y") {
          history.replace({state: {}});
          return;
        }

        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          let oddMax = 0, evenMax = 0;
          const loc = result?.loc;
          Object.entries(loc).map(([k, v], index) => {
            const odd = index % 2 === 1;
            if (odd) {
              oddMax = Math.max(oddMax, Array.from({length: v.x})?.length);
            } else {
              evenMax = Math.max(evenMax, Array.from({length: v.x})?.length);
            }
          });

          setLoc(result?.loc);

          setTimeout(() => {
            $('.div-even').css({width: (evenMax * 50) + 50, minWidth: (evenMax * 50) + 50});
            $('.div-odd').css({width: (oddMax * 50) + 50, minWidth: (oddMax * 50) + 50});
          }, 300);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
        $("#overlay").hide();
      });
  }

  function DateGab(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    // Convert the date strings to Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Calculate the difference in days
    const diffInDays = Math.round(Math.abs((startDate - endDate) / oneDay));

    return diffInDays;
  }

  const loadModal = (val) => {
    axios2
      .post(`/locker/locker/name/reg/ready?val=${val}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalOpen(true);
          $('#lo_posi').val(val);
          $('#lo_posi_prt').html(val);
          $('#lo_num').val(result?.loNum);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    axios2.postEx("/locker/name/reg", {
      lo_posi: $('#lo_posi').val(),
      lo_num: $('#lo_num').val(),
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert('수정하였습니다');
        setRenew(Fn.getRenewTime());
        setModalOpen(false);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
        // $(".loading_bg").fadeOut("fast");
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      {
        Object.entries(loc).map(([k, v], index) => {
          k = k.replace("_", "");

          const evenRow = index % 2 === 0;
          const lastRow = Object.entries(loc)?.length === index + 1;

          if (!evenRow) {
            setTimeout(() => {
              if ($('#div_' + index).parents('.wrap-' + index).length === 0)
                $('#div_' + (index - 1) + ',#div_' + index).wrapAll('<div class="div-locker wrap-' + index + ' d-flex m-4 flex_gap2"></div>');
            });
          } else {
            if (lastRow && Object.entries(loc)?.length % 2 === 1) {
              if (!$('#div_' + index).parent().hasClass('div-locker')) {
                setTimeout(() => {
                  $('#div_' + index).wrapAll('<div class="div-locker div-locker-wrap wrap-' + index + ' d-flex m-4 flex_gap2" style="min-width: 100% !important; padding-right: 40px !important;"></div>')
                  //$('.wrap-' + index).append(`<div class="div-locker"></div>`);
                });
              }
            }
          }
          return (
            <div id={`div_${index}`} className={`div-locker p-0 text-left table_box ${evenRow ? `div-even` : `div-odd`}`}>
              <p className="border-bottom p-2">
                <span className="h7 bold700 px-2"><strong>{k}</strong> : {v.y} &times; {v.x} ({v.x * v.y}) {v.s}~{v.e}</span>
              </p>
              <div className="m-3">
                <table className="view-rounded locker_view" style={{width: "auto"}}>
                  {Array.from({length: v.y}).map((_, a) => (
                    <tr key={a}>
                      {Array.from({length: v.x}).map((_, b) => {
                        const po = `${k}-${a + v.s}-${b + 1}`;
                        // let Lnum = a + (b * v.y) + 1 + (v.s - 1);
                        let Lnum = v[`${k}-${a + v.s}-${b + 1}`].Lnum;
                        let end_date, gubun, click, bgclr, po2;

                        const e_chk_row = v;

                        if (e_chk_row?.lo_posi) {
                          Lnum = e_chk_row.lo_num;
                        } else {
                          Lnum = Lnum;
                        }

                        if (e_chk_row?.locker === "Y") {
                          end_date = e_chk_row.locker_end_date;
                          gubun = "locker";
                        } else if (e_chk_row?.g_locker === "Y") {
                          end_date = e_chk_row.g_locker_end_date;
                          gubun = "g_locker";
                        } else {
                          end_date = e_chk_row?.locker_end_date;
                          gubun = "locker";
                        }

                        const now_date = Fn.getDate(new Date());

                        if (!e_chk_row?.mb_no) {
                          click = "N";
                          bgclr = "#e8e8e8";
                        } else {
                          if (end_date >= now_date) {
                            click = "Y";
                            bgclr = "#FFFFFF";
                          } else {
                            const gab = DateGab(end_date, now_date);
                            if (gab < 14) {
                              click = "Y";
                              bgclr = "#bdfdd9";
                            } else if (gab < 28) {
                              click = "Y";
                              bgclr = "#fed8f8";
                            } else if (gab < 30) {
                              click = "Y";
                              bgclr = "#ff8e8e";
                            } else {
                              click = "Y";
                              bgclr = "#bcb5fb";
                            }
                          }
                        }

                        bgclr = "#FFFFFF";

                        po2 = `${end_date} ${e_chk_row?.mb_name}`;

                        return (
                          <th
                            key={b}
                            width="50"
                            align="center"
                            style={{cursor: (v.s <= Lnum && v.e >= Lnum) ? "pointer" : undefined, backgroundColor: bgclr, maxWidth: 60}}
                            title={click === "Y" ? (v.s <= Lnum && v.e >= Lnum) && po2 : (v.s <= Lnum && v.e >= Lnum) && po}
                            onClick={() => (v.s <= Lnum && v.e >= Lnum) && loadModal(`${k}-${a + v.s}-${b + 1}`)}
                          >
                            {(v.s <= Lnum && v.e >= Lnum) && <span id={`L${Lnum}`}>{Lnum}</span>}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </table>
                <div style={{paddingBottom: "10px"}}></div>
              </div>
            </div>
          );
        })
      }

      <input type="hidden" id="lo_posi"/>

      {/*팝업*/}

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '650px'}}>
            <div className="POP_title">
              <h5>
                라커관리 > <strong>라커 번호설정</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="120px"/>
                  <col width="*"/>
                  <col width="120px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>좌표</th>
                  <td><span id="lo_posi_prt"></span></td>
                  <th>번호</th>
                  <td>
                    <input type="text" className="form-control table-inline" placeholder="" id="lo_num"/>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center my-3 pt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>
                설정
              </button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModalOpen(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default LockerNumber;
