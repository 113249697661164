import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";
import moment from "moment/moment";

// datepicker bind 외에 스크립트 없음
const AnalysisBranchPrice = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#basic_date').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/analysis/branch/price/ready?basic_date=${$('#basic_date').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const downloadExcel = () => {
    let url = `/xls/analysis/branch/price?basic_date=${$('#basic_date').val()}&branch_code=${localStorage.getItem("USER.systemBranchCode")}`;
    if (url) {
      Fn.download(Constants.API_URL + url);
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX ">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <JDatePicker className="form-control" id="basic_date"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => downloadExcel()}>엑셀저장</button>
          </div>
        </div>

        <div className="m-3 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="115px"/>
              <col width="150px"/>
              <col width="95px"/>
              <col width="*"/>
              <col width="125px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="100px"/>
              <col width="100px"/>
              <col width="125px"/>
              <col width="125px"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>회원번호</th>
              <th>회원명</th>
              <th>서비스등록일</th>
              <th>서비스적요</th>
              <th>서비스금액</th>
              <th>상품기간</th>
              <th>일단가</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>기준일</th>
              <th>경과일</th>
              <th>남은일수</th>
              <th>실매출액</th>
              <th>남은금액</th>
            </tr>

            {rets && rets.map((row, i) => {
              const mb_no = row.mb_no;
              const mb_id = row.mb_id;
              const mb_name = row.mb_name;
              let start_date = row.start_date;
              let end_date = row.end_date;

              const ll_row = row?.llRow;

              const ap_date = ll_row.ap_date;
              const period = ll_row.period;
              const ap_amt = ll_row.ap_amt;
              start_date = ll_row.start_date;

              const add_date = period * 30;
              if (start_date != '') {
                end_date = new Date(start_date);
                end_date.setDate(end_date.getDate() + add_date);
                try {
                  end_date = Fn.getDate(end_date);
                } catch (err) {
                }
              } else {
                end_date = "";
              }

              let day_price = 0;
              if (period) {
                day_price = Math.round(ap_amt / (period * 30));
              }

              let use_day = '-';
              let remain_day = '-';
              if (start_date) {
                const diff = Fn.getDateDiff($('#basic_date').val(), start_date);
                use_day = Math.max(Math.min(diff, period * 30), 0);
                remain_day = add_date - use_day;
              }

              let real_price = 0;
              if (use_day) {
                real_price = day_price * use_day;
                real_price = Math.min(real_price, ap_amt);
              }

              const remain_price = ap_amt - real_price;

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td style={{msoNumberFormat: '@'}}>{mb_id}</td>
                  <td>{mb_name}</td>
                  <td>{ll_row?.ap_date}</td>
                  <td>{ll_row?.ap_pdtName}</td>
                  <td>
                    <div align="right" style={{paddingRight: '10px'}}>
                      {Fn.numberWithCommas(ap_amt)}
                    </div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: '10px'}}>{period}개월</div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: '10px'}}>
                      {Fn.numberWithCommas(day_price)}
                    </div>
                  </td>
                  <td>{start_date}</td>
                  <td>{end_date}</td>
                  <td>{$('#basic_date').val()}</td>
                  <td>{use_day}</td>
                  <td>{remain_day}</td>
                  <td>
                    <div align="right" style={{paddingRight: '10px'}}>
                      {Fn.numberWithCommas(real_price)}
                    </div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: '10px'}}>
                      {Fn.numberWithCommas(remain_price)}
                    </div>
                  </td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={15}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-center border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default AnalysisBranchPrice;
