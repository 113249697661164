import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import Fn from "../../libraries/Fn";
import $ from "jquery";

const AppCardReader = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isComposing, setIsComposing] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = () => {
    // 영문자와 공백만 허용
    const filteredValue = $('#data').val().replace(/[^a-zA-Z0-9\s\-_=!@#$%^&*()_+{}[\]:;'"<>,.?|\\\/~`]/g, "");
    $('#data').val(filteredValue);

    const data = $('#data').val()?.trim();

    if (!data || data == '앱카드 데이터') {
      try {
        $('#pay_form')[0].edtCardNum.value = "";
      } catch (err) {
      }
      $('#data').val('앱카드 데이터');
      $('#data')[0].focus();
      $('#data').select();
      return alert('앱카드 데이터가 정상적으로 처리되지 않았습니다.');
    }

    try {
      $('#pay_form')[0].edtCardNum.value = data;
    } catch (err) {
      console.error(err);
    }

    if (props?.onCallback) {
      props?.onCallback();
    }

    onToggleModal();
  };

  // 입력값 필터링 함수
  const handleInputChange = (e) => {
    if (!isComposing) {
      // 영문자와 공백만 허용
      const filteredValue = e.target.value.replace(/[^a-zA-Z0-9\s\-_=!@#$%^&*()_+{}[\]:;'"<>,.?|\\\/~`]/g, "");
      $('#data').val(filteredValue);
    }
  };

  // IME 입력 시작
  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  // IME 입력 종료
  const handleCompositionEnd = (e) => {
    setIsComposing(false);
    // IME 입력 종료 후 값 검증
    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9\s\-_=!@#$%^&*()_+{}[\]:;'"<>,.?|\\\/~`]/g, "");
    $('#data').val(filteredValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        const chk = ((localStorage.getItem("USER.systemID") === "futureinfo" ||
            localStorage.getItem("USER.systemID") === "spoany" ||
            localStorage.getItem("USER.systemID") === "tpwls8905") ||
          Fn.getCurrentDate() >= '2024-12-01');

        if (!chk) {
          alert('현재 앱카드결제 서비스는 준비 중입니다.');
          setIsOpen(false);
        }

        Fn.centerModal();
        try {
          $('#pay_form')[0].edtCardNum.value = "";
        } catch (err) {
        }
        $('#data').val('앱카드 데이터');
        $('#data')[0].focus();
        $('#data').select();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '600px'}}>
          <div className="POP_title">
            <h5><strong>앱카드 결제</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>
          <div className="POP_cnt_box">
            <div className="mb-3 d-flex flex-nowrap">
              <form name="input_form" id="input_form" method="post">
                <table className="view-rounded sub_view" style={{borderRadius: 0, border: 0}}>
                  <tr>
                    <td align={"center"}><h4>앱카드를 리더기로 읽어주세요</h4></td>
                  </tr>
                  <tr>
                    <td align={"center"}>
                      <input name="data" id="data" type="text" className="form-control wid80" placeholder="" style={{imeMode: "disabled", backgroundColor: "#dfdfdf"}}
                             onKeyDown={(e) => {
                               if (e.keyCode == 13)
                                 handleComplete()
                             }}
                             onChange={handleInputChange}
                             onCompositionStart={handleCompositionStart}
                             onCompositionEnd={handleCompositionEnd}
                      />
                    </td>
                  </tr>
                </table>
              </form>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mr-2" onClick={() => handleComplete()}>확인</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setIsOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppCardReader;
