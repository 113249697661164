import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import SelectLocker from "../common/SelectLocker";
import AppCardReader from "../common/AppCardReader";

const MvMembership = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [tabIdx, setTabIdx] = useState(1);
  const [item_form, setItemForm] = useState();
  const [isLockerOpen, setIsLockerOpen] = useState(false);

  const [isCardReaderOpen, setIsCardReaderOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };


  function input_reset() {
    $('#ip_method').val('');
    $('#ip_amt').val('');
    $('#ip_cardName').val('');
    $('#ip_etc').val('');
    $('#ip_cardName').hide();
    $('#ip_bank').hide();
  }

  function it_menu_close() {
    $('.ca10').each(function () {
      $(this).parents('dd').removeClass('ON');
    });
    $('.ca20').each(function () {
      $(this).parents('dd').removeClass('ON');
    });
    $('.ca30').each(function () {
      $(this).parents('dd').removeClass('ON');
      $(this).next().parents('dd').removeClass("ON");

      $("#ca30_reason" + $(this).attr("id")).val("")
      $("#ca30_reason" + $(this).attr("id")).prop('disabled', false);
    });

    $('#1342000261').each(function () {
      $(this).parents('dd').removeClass('ON');
      $(this).next().parents('dd').removeClass("ON");
    });

    $("#ca20_1342000261_val").val("1")
    $("#ca20_1342000261_val").prop('disabled', false);

    $('#ptday').each(function () {
      $(this).parents('dd').removeClass('ON');
      $(this).next().parents('dd').removeClass("ON");
    });

    $("#ca20_ptday_val").val("1")
    $("#ca20_ptday_val").prop('disabled', false);


    $('.op_ca20').each(function () {
      $(this).parents('dd').removeClass('ON');
    });
    $('.op_ca30').each(function () {
      $(this).parents('dd').removeClass('ON');
      $(this).next().parents('dd').removeClass("ON");

      $("#op_ca30_reason" + $(this).attr("id")).val("")
      $("#op_ca30_reason" + $(this).attr("id")).prop('disabled', false);
    });
    $('.it_etc').each(function () {
      $(this).parents("dd").removeClass('ON');
    });


    $('*[id^=it_show]').remove();
    $('*[id^=it_in]').remove();

    $('*[id^=it_op_show]').remove();
    $('*[id^=it_op_in]').remove();

    $('*[id^=it_pt_show]').remove();
    $('*[id^=it_pt_in]').remove();

    $("#op_period_month").val('0');

    $("#pt_cnt").val('');
    $("#pt_price").val('');
    $("#r_pt").val('');
    $("#p_pt").val('');

    $("#tot_sum_show").html("0")
    $("#period_show").html("")

    $('#tab_menu a:first').tab('show')
    $('#item_form')[0].reset();

    $(".layer_bg").hide();
  }

  window.del_it_pt = (pid) => {
    $("#it_pt_show" + pid).remove();
    $("#it_pt_in" + pid).remove();
    it_cal()
  }

  function it_cal(auto_cut) {
    //현재 선택된 탭의 아이디
    var NowTab = $(`input[name='tab_item']:checked`).attr("desc");

    //선택한 탭이 기본일때는 해당 브랜드 아닐때는 지점의 브랜드
    // TODO
    //var NowBrand = $(`input[name='tab_item']:checked`).val();

    var new_activeTab = NowTab.substr(0, 12);

    var NowBrand;
    if (new_activeTab == "#menu_basics") {
      NowBrand = NowTab.replace("#menu_basics", "");
    } else {
      NowBrand = $("#NowBrand").text();
    }


    var it_sum = 0, op_sum = 0, pt_sum = 0;
    $('.it_amounts').each(function () {
      it_sum += parseFloat($(this).val());
    });

    $('.it_op_amounts').each(function () {
      op_sum += parseFloat($(this).val());
    });

    $('.it_pt_amounts').each(function () {
      pt_sum += parseFloat($(this).val());
    });

    var pay_price = $("#pay_price").val();
    var cut_price = $("#cut_price").val();

    //기본의 부가세제외
    if ($("#it_tax_free" + NowBrand).parents("dd").hasClass("ON")) {
      var it_tax_free_chk = 'Y';
    } else {
      var it_tax_free_chk = 'N';
    }

    //기본의 1일2회이용
    if ($("#it_twice_daily" + NowBrand).parents("dd").hasClass("ON")) {
      var twice_daily_chk = 'Y';
    } else {
      var twice_daily_chk = 'N';
    }


    if ($("#it_op_tax_free").parents("dd").hasClass("ON")) {
      var it_op_tax_free_chk = 'Y';
    } else {
      var it_op_tax_free_chk = 'N';
    }

    if ($("#it_pt_tax_free").parents("dd").hasClass("ON")) {
      var it_pt_tax_free_chk = 'Y';
    } else {
      var it_pt_tax_free_chk = 'N';
    }

    //10% 할인
    if ($("#dis_10").parents("dd").hasClass("ON")) {
      var it_dis_chk = 'Y';
      $('#item_form')[0].dis_10_YN.value = 'Y';
    } else {
      var it_dis_chk = 'N';
      $('#item_form')[0].dis_10_YN.value = 'N';
    }

    //5% 할인
    if ($("#dis_5").parents("dd").hasClass("ON")) {
      var it_dis_5_chk = 'Y';
      $('#item_form')[0].dis_5_YN.value = 'Y';
    } else {
      var it_dis_5_chk = 'N';
      $('#item_form')[0].dis_5_YN.value = 'N';
    }


    //받은돈 초기화
    if (auto_cut != "no") {
      $('#item_form')[0].payCard.value = "";
      $('#item_form')[0].pay_price.value = "";
      $('#card_month')[0].innerHTML = "";
    }


    //포인트 사용금액

    //쿠폰 사용금액
    var use_point = $('#item_form')[0].use_point.value;
    var coupon_price = $('#item_form')[0].coupon_price.value;

    $.ajax({
      type: "post",
      url: "/member/item/calculation",
      data: "tot_it_price=" + it_sum + "&tot_op_price=" + op_sum + "&tot_pt_price=" + pt_sum + "&pay_price=" + pay_price + "&cut_price=" + cut_price + "&it_tax_free_chk=" + it_tax_free_chk + "&twice_daily_chk=" + twice_daily_chk + "&it_op_tax_free_chk=" + it_op_tax_free_chk + "&it_pt_tax_free_chk=" + it_pt_tax_free_chk + "&auto_cut=" + auto_cut + "&NowBrand=" + NowBrand + "&use_point=" + use_point + "&coupon_price=" + coupon_price + "&it_dis_chk=" + it_dis_chk,
      success: function (data) {
        data = data?.message;

        var data2 = data.split("||");

        $('#item_form')[0].tot_amount.value = SetPriceInput(String(it_sum + op_sum + pt_sum))
        try {
          $('#item_form')[0].tot_sum_show.value = SetPriceInput(String(it_sum + op_sum + pt_sum))
        } catch (err) {
        }

        $('#item_form')[0].tot_tax.value = SetPriceInput(data2[0])
        $('#item_form')[0].twice_daily_price.value = SetPriceInput(data2[1])
        $('#item_form')[0].tot_amount_sum.value = SetPriceInput(data2[2])
        $('#item_form')[0].unpaid_price.value = SetPriceInput(data2[3])

        if (data2[4]) //자동절사부분
          $('#item_form')[0].cut_price.value = SetPriceInput(data2[4])
      }
    });

    var period_month = $("#period_month").val();
    var period_day = $("#period_day").val();

    var period_txt = "";
    if (period_month > 0) period_txt = period_month + "Month "
    if (period_day > 0) period_txt = period_txt + period_day + "Day"

    $("#period_show").html(period_txt)
  }

  function SetPriceInput(str) {
    str = str.replace(/,/g, '');

    return Fn.numberWithCommas(str);

    var retValue = "";
    for (let i = 1; i <= str.length; i++) {
      if (i > 1 && (i % 3) == 1)
        retValue = str.charAt(str.length - i) + "," + retValue;
      else
        retValue = str.charAt(str.length - i) + retValue;
    }
    return retValue;
  }

  function it_cal2() {
    var tot_amount_sum = $('#item_form')[0].tot_amount_sum.value

    var cut_price = $("#cut_price").val();
    var pay_price = $("#pay_price").val();

    if (!tot_amount_sum) {
      tot_amount_sum = 0
    } else {
      tot_amount_sum = Fn.cfNumeric(tot_amount_sum)
    }
    if (!cut_price) {
      cut_price = 0
    } else {
      cut_price = Fn.cfNumeric(cut_price)
    }
    if (!pay_price) {
      pay_price = 0
    } else {
      pay_price = Fn.cfNumeric(pay_price)
    }

    var r_price = tot_amount_sum - cut_price - pay_price

    $('#item_form')[0].unpaid_price.value = SetPriceInput(String(r_price))
  }

  function it_period_chk(chk) //chk 값은 설정 선택할때 값
  {
    var period_kind = $(':radio[name="period_kind"]:checked').val();
    var period_month = $("#period_month").val();
    var period_day = $("#period_day").val();
    var it_start_date = $("#it_start_date").val();

    var mb_no = props.mbNo;

    if (mb_no == "") {
      alert('회원정보를 확인할수 없습니다.');
      return;
    }

    //현재 선택된 ca10 값
    var select_item_id;
    $.each($(".ca10"), function (index, item) {
      if ($(this).parents('dd').hasClass("ON")) select_item_id = $(this).attr("id");
    });
    // var select_item_id = $(".ca10.on").attr("id");

    if (select_item_id) {
      select_item_id = select_item_id;
    } else {
      select_item_id = "";
    }

    $.ajax({
      type: "post",
      url: "/member/it/period/chk",
      data: "period_kind=" + period_kind + "&period_month=" + period_month + "&period_day=" + period_day + "&mb_no=" + mb_no + "&chk=" + chk + "&it_start_date=" + it_start_date + "&select_item_id=" + select_item_id,
      success: function (data) {
        data = data?.message;

        if (data == "err") {
          alert('회원정보를 확인할수 없습니다.');
          return;
        }

        var data2 = data.split("||");

        if (data2[0] > 0 && chk == 3) {
          if (window.confirm("해당회원은 회원권기간이 남아있습니다.\n재등록으로 기간설정을 하시겠습니까?")) {
            $("#it_start_date").val(data2[1])
            $("#it_end_date").val(data2[2])
            $("#it_end_date_show").html(data2[2])
          }
        } else {
          $("#it_start_date").val(data2[1])
          $("#it_end_date").val(data2[2])
          $("#it_end_date_show").html(data2[2])
        }

      }
    });
  }

  const loadScripts = () => {
    const item_form = $('#item_form')[0];
    setItemForm($('#item_form')[0]);

    item_form.reg_step.value = $('#_reg_step option:selected').text() || '';

    $(".ca10, .ca20, .ca30").unbind("click").on("click", function () {
      var it_id = $(this).attr("id");
      var period_month = $("#period_month").val();
      var period_day = $("#period_day").val();

      var period_kind = $(':radio[name="period_kind"]:checked').val();
      if (typeof period_kind == "undefined") period_kind = 0;

      if ($(this).parents('dd').hasClass("ON")) {

        $(this).parents('dd').removeClass('ON');
        $(this).next().parents('dd').removeClass("ON");

        $("#ca30_reason" + it_id).prop('disabled', false);
        $("#ca20_1342000261_val").prop('disabled', false);
        $("#ca20_ptday_val").prop('disabled', false);

        var in_it_period_txt = $("#in_it_period_txt" + it_id).val().split("_")
        if (in_it_period_txt[0] == "day") {
          $("#period_day").val(period_day - in_it_period_txt[1])
        } else {
          $("#period_month").val(period_month - in_it_period_txt[1])
        }

        var in_it_suit = $("#in_it_suit" + it_id).val()
        var in_it_locker = $("#in_it_locker" + it_id).val()

        if (in_it_suit > 0) {
          $("#suit").prop("checked", false)
          $("#suit_n").val('')
        }
        if (in_it_locker > 0) {
          $("#locker").prop("checked", false)
          $("#locker_n").val('')

          $("#it_locker_name").hide();
          $("#it_locker_pass").hide();
          $("#it_locker_id").hide();
        }

        if (it_id == '1341129345') //라커 //강제
        {
          $("#locker_n").val('')
        }

        if (it_id == 10) {
          $("#locker_g").val('')
        }


        $("#it_show" + it_id).remove();
        $("#it_in" + it_id).remove();

        it_period_chk()
        it_cal()

        if (item_form?.coupon) $('#item_form')[0].coupon.value = "";
        $('#item_form')[0].coupon_price.value = "0";
        $('#item_form')[0].use_point.value = "0";
      } else {
        if (item_form?.coupon) $('#item_form')[0].coupon.value = "";
        $('#item_form')[0].coupon_price.value = "0";
        $('#item_form')[0].use_point.value = "0";

        if ($(".it_amounts").length > 6) //선택최대갯수 6개
        {
          alert("더이상 항목을 추가할 수 없습니다.");
          return;
        }

        if ($(this).attr("class") == "ca20" && it_id == "1342000261") //일일이용
        {
          var ca20_1342000261_val = $.trim($("#ca20_1342000261_val").val())
          if (ca20_1342000261_val == "" || ca20_1342000261_val == "0") {
            alert('인원을 입력해주세요')
            $("#ca20_1342000261_val" + it_id).focus();
            return;
          }

          $("#ca20_1342000261_val").prop('disabled', true);
        }

        if ($(this).attr("class") == "ca20" && it_id == "ptday") //일일PT이용
        {
          var ca20_ptday_val = $.trim($("#ca20_ptday_val").val())
          if (ca20_ptday_val == "" || ca20_ptday_val == "0") {
            alert('인원을 입력해주세요')
            $("#ca20_ptday_val" + it_id).focus();
            return;
          }

          $("#ca20_ptday_val").prop('disabled', true);
        }

        if ($(this).attr("class") == "ca30") {
          var ca30_reason = $.trim($("#ca30_reason" + it_id).val())
          if (ca30_reason == "") {
            alert('사유를 입력해주세요')
            $("#ca30_reason" + it_id).focus();
            return;
          }

          $("#ca30_reason" + it_id).prop('disabled', true);
        }

        $(this).parents('dd').addClass("ON");
        $(this).next().parents('dd').addClass("ON");

        if ($("#suit").prop("checked")) var suit_chk = 1
        else var suit_chk = 0

        if ($("#locker").prop("checked")) var locker_chk = 1
        else var locker_chk = 0

        //alert(locker_chk)

        //ca10은 한개만 설정가능
        var dd = 0;
        $.each($(".ca10"), function (index, item) {
          if ($(this).parents('dd').hasClass("ON")) dd++;
        });
        // var dd = $(".ca10.on").length;

        if (dd > 1) {
          alert("기간이 있는 기본상품은 1개만 선택가능합니다");
          $(this).parents('dd').removeClass('ON');
          $(this).next().parents('dd').removeClass("ON");
          return;
        }

        //선택된 탭의 브랜드
        var NowTab = $(`input[name='tab_item']:checked`).attr("desc");

        var new_activeTab = NowTab.substr(0, 12);

        var NowBrand;
        if (new_activeTab == "#menu_basics") {
          NowBrand = NowTab.replace("#menu_basics", "");
        } else {
          NowBrand = $("#NowBrand").text();
        }

        let NowBranch_code = $("#NowBranch_code").text();

        if (!NowBrand) {
          NowBrand = "F";
        }

        //10% 할인
        if ($("#dis_10").parents("dd").hasClass("ON")) {
          var it_dis_chk = 'Y';
          $('#item_form')[0].dis_10_YN.value = 'Y';
        } else {
          var it_dis_chk = 'N';
          $('#item_form')[0].dis_10_YN.value = 'N';
        }

        //5% 할인
        if ($("#dis_5").parents("dd").hasClass("ON")) {
          var it_dis_5_chk = 'Y';
          $('#item_form')[0].dis_5_YN.value = 'Y';
        } else {
          var it_dis_5_chk = 'N';
          $('#item_form')[0].dis_5_YN.value = 'N';
        }


        console.log("it_id=" + it_id + "&period_month=" + period_month + "&period_day=" + period_day + "&ca30_reason=" + ca30_reason + "&ca20_1342000261_val=" + ca20_1342000261_val + "&ca20_ptday_val=" + ca20_ptday_val + "&suit_chk=" + suit_chk + "&locker_chk=" + locker_chk + "&NowBrand=" + NowBrand + "&it_dis_chk=" + it_dis_chk + "&it_dis_5_chk=" + it_dis_5_chk + "&NowBranch_code=" + NowBranch_code);
        $.ajax({
          type: "post",
          url: "/member/it/basic/chk",
          data:
            "it_id=" + it_id +
            "&period_month=" + period_month +
            "&period_day=" + period_day +
            "&ca30_reason=" + (ca30_reason || '') +
            "&ca20_1342000261_val=" + (ca20_1342000261_val || '') +
            "&ca20_ptday_val=" + (ca20_ptday_val || '') +
            "&suit_chk=" + (suit_chk || '') +
            "&locker_chk=" + (locker_chk || '') +
            "&NowBrand=" + NowBrand +
            "&it_dis_chk=" + (it_dis_chk || '') +
            "&it_dis_5_chk=" + (it_dis_5_chk || '') +
            "&NowBranch_code=" + (NowBranch_code || ''),
          success: function (data) {
            console.log(data)
            data = data?.message;

            var data2 = data.split("||");

            $("#item_list_show").append(data2[0])
            $("#item_list_in").append(data2[1])

            $("#period_month").val(data2[2])
            $("#period_day").val(data2[3])

            if (data2[4] > 0) {
              $("#suit").prop("checked", true)
              $("#suit_n").val('Y')
            }
            if (data2[5] > 0) {
              var mb_no = props.mbNo;

              $("#locker").prop("checked", true)
              $("#locker_n").val('Y')

              $.ajax({
                type: "post",
                url: "/member/it/member/locker/chk",
                data: "mb_no=" + mb_no,
                success: function (subdata) {
                  subdata = subdata?.message;

                  var subdata2 = subdata.split("||");

                  $('#item_form')[0].mnpLocName.value = subdata2?.[0] || ""
                  $('#item_form')[0].mnpLocPass.value = subdata2?.[1] || ""
                  $('#item_form')[0].mnpLocNum.value = subdata2?.[2] || ""

                  $("#it_locker_name").show();
                  $("#it_locker_pass").show();
                  $("#it_locker_id").show();
                },
                error: function (e) {
                  alert(e);
                }
              });
            }

            if (it_id == '1341129345') //라커 //강제
            {
              $("#locker_n").val('Y')
            }

            if (it_id == '10') //골프라커 //강제
            {
              $("#locker_g").val('Y')
            }

            it_period_chk()
            it_cal()
          },
          error: function (e) {
            alert(e);
          }
        });
      }
    });

    //--------------------------------------------------------
    function onItemClicked(selector) {
      $(selector).each(function () {
        const $t = $(this);
        $t.parents('dd').css('cursor', 'pointer');
        $t.parents('dd').on("click", function (e) {
          const $target = $(e.target).find(selector);
          if (!$target.is('input')) {
            $target.trigger('click');
            e.preventDefault();
            e.stopPropagation();
          }
        });
      });
    }

    onItemClicked(".ca10, .ca20, .ca30");
    onItemClicked(".it_etc");
    onItemClicked(".op_ca20, .op_ca30");
    $('.cls-num').val('1');
    //--------------------------------------------------------

    $(".it_etc").on("click", function () {
      if ($(this).parents('dd').hasClass("ON")) {
        $(this).parents('dd').removeClass('ON');
      } else {
        $(this).parents('dd').addClass("ON");
      }
      it_cal()
    });

    $(".op_ca20, .op_ca30").on("click", function () {
      var it_id = $(this).attr("id")
      var op_period_month = $("#op_period_month").val();
      var op_period_day = $("#op_period_day").val();
      var mb_no = props.mbNo;

      var remain_period;
      if ($("#remain_period").prop("checked")) remain_period = 1;
      else remain_period = 0;

      if ($(this).parents('dd').hasClass("ON")) {
        $(this).parents('dd').removeClass('ON');
        $(this).next().parents('dd').removeClass("ON");

        $("#op_ca30_reason" + it_id).prop('disabled', false);

        var in_it_suit = $("#in_it_op_suit" + it_id).val()
        var in_it_locker = $("#in_it_op_locker" + it_id).val()

        if (in_it_suit > 0) $("#suit").prop("checked", false)
        if (in_it_locker > 0) {
          if (it_id == '1341129345') //라커 //강제
          {
            $("#locker_n").val('')
          }

          if (it_id == 10) //골프라커 //강제
          {
            $("#locker_g").val('')
          }

          $("#locker").prop("checked", false)

          $("#it_locker_name").hide();
          $("#it_locker_pass").hide();
          $("#it_locker_id").hide();
        }

        $("#it_op_show" + it_id).remove();
        $("#it_op_in" + it_id).remove();

        it_cal()
      } else {
        if ($(this).attr("class") == "op_ca30") {
          var ca30_reason = $.trim($("#op_ca30_reason" + it_id).val())
          if (ca30_reason == "") {
            alert('사유를 입력해주세요')
            $("#op_ca30_reason" + it_id).focus();
            return;
          }
        }

        $("#op_ca30_reason" + it_id).prop('disabled', true);

        //$(this).attr("class","on");
        $(this).parents('dd').addClass("ON");
        $(this).next().parents('dd').addClass("ON");

        let NowBranch_code = $("#NowBranch_code").text();

        $.ajax({
          type: "post",
          url: "/member/it/op/chk",
          data: "it_id=" + it_id + "&op_period_month=" + op_period_month + "&op_period_day=" + op_period_day + "&ca30_reason=" + ca30_reason + "&mb_no=" + mb_no + "&remain_period=" + remain_period + "&NowBranch_code=" + NowBranch_code,
          success: function (data) {
            data = data?.message;

            var data2 = data.split("||");

            if (data2[0] == "err1") {
              alert("연기내역이 등록되어있어 신청할 수 없습니다.");
              return;
            }

            $("#item_list_show").append(data2[0])
            $("#item_list_in").append(data2[1])

            if (data2[2] > 0) $("#suit").prop("checked", true)
            if (data2[3] > 0) {
              if (it_id == '1341129345') //라커 //강제
              {
                $("#locker_n").val('Y')
              }

              if (it_id == '10') //골프라커 //강제
              {
                $("#locker_g").val('Y')
              }

              $("#locker").prop("checked", true)

              $.ajax({
                type: "post",
                url: "/member/it/member/locker/chk",
                data: "mb_no=" + mb_no,
                success: function (subdata) {
                  subdata = subdata?.message;

                  var subdata2 = subdata.split("||");

                  $('#item_form')[0].mnpLocName.value = subdata2?.[0] || '';
                  $('#item_form')[0].mnpLocPass.value = subdata2?.[1] || '';
                  $('#item_form')[0].mnpLocNum.value = subdata2?.[2] || '';

                  $("#it_locker_name").show();
                  $("#it_locker_pass").show();
                  $("#it_locker_id").show();


                },
                error: function (e) {
                  alert(e);
                }
              });

            }

            //$("#period_month").val(data2[2])
            //$("#period_day").val(data2[3])

            //if(data2[4]>0) $("#suit").prop("checked",true)
            //if(data2[5]>0) $("#locker").prop("checked",true)

            it_cal()

          },
          error: function (e) {
            alert(e);
          }
        });
      }
    });

    $(".period_kind").on("click", function () {
      //선택한 기준상품
      it_period_chk($(this).val());
    });

    $("#close_layer").on("click", function () {
      it_menu_close()
    });

    // TODO : 체크 필요
    /*
    $("#openmenu").on("click", function () {

      service_ok.style.display = "inline";
      la_cl.style.display = "inline";
      la_cl2.style.display = "inline";
      service_ing.style.display = "none";


      if (member_form.mb_no.value == "") {
        alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
        s_form.search_auth_code.focus();
        return;
      }


      if (s_form.auth_code.value == "") {
        alert('인증코드를 입력해주세요');
        s_form.search_auth_code.focus();
        return;
      }


      if (member_form.temp_mb_birth.value == '') {
        alert('생일이 입력되지 않았습니다. 회원정보 수정후 다시 시도해주세요');
        return;
      }
      if (member_form.temp_mb_sex.value == '') {
        alert('성별이 입력되지 않았습니다. 회원정보 수정후 다시 시도해주세요');
        return;
      }
      if (member_form.temp_visit_course.value == '') {
        alert('가입경로가 입력되지 않았습니다. 회원정보 수정후 다시 시도해주세요');
        return;
      }

      if (member_form.temp_addr_code.value == '') {
        alert('주소가 입력되지 않았습니다 회원정보 수정후 다시 시도해주세요');
        return;
      }

      //블랙리스트는 구매못하게 처리
      var black_img = $("#member_pic_view").attr("src");
      if (black_img == "../image/member_thumb_b.png") {
        alert('주문등록이 금지된 회원입니다.');
        return;
      }

      $(".layer_bg").show();
    });
    */

    $("#remain_period").on("click", function () {
      if ($(this).prop("checked")) {
        var op_period_month = $("#op_period_month").val("0");
        var op_period_day = $("#op_period_day").val("0");
      }
    });

    $("#op_period_month, #op_period_day").on("change", function () {
      var op_period_month = $("#op_period_month").val();
      var op_period_day = $("#op_period_day").val();
      if (op_period_month != '' || op_period_day != '') {
        $("#remain_period").prop("checked", false);
      }
    });

    $("#it_pt_reg").on("click", function () {
      var pt_cnt = $("#pt_cnt").val();
      var pt_price = $("#pt_price").val().replaceAll(",", "");
      var r_pt = $("#r_pt option:selected").val() || '';
      var p_pt = $("#p_pt option:selected").val() || '';

      if (!pt_cnt) {
        alert('PT 횟수를 입력해주세요');
        $("#pt_cnt").focus();
        return;
      }
      if (!pt_price) {
        alert('PT 금액을 입력해주세요');
        $("#pt_price").focus();
        return;
      }
      if (!r_pt) {
        alert('접수트레이너를 선택해주세요');
        $("#r_pt").focus();
        return;
      }
      if (!p_pt) {
        alert('실시트레이너를 선택해주세요');
        $("#p_pt").focus();
        return;
      }

      $.ajax({
        type: "post",
        url: "/member/it/pt/chk",
        data: "pt_cnt=" + pt_cnt + "&pt_price=" + pt_price + "&r_pt=" + r_pt + "&p_pt=" + p_pt,
        success: function (data) {
          data = data?.message;

          var data2 = data.split("||");

          $("#item_list_show").append(data2[0])
          $("#item_list_in").append(data2[1])

          it_cal()
        }
      });
    });
  }

  const load = () => {
    if (isOpen) {
      axios2
        .post(`/member/member/view/ready?mb_no=${props.mbNo}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setResult(result);
            console.log(result);
            setTimeout(() => {
              loadScripts();
            }, 0);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const onTab = (idx) => {
    setTabIdx(idx);
    setTimeout(() => {
      loadScripts();
      onTabSub();
    }, 500);
  }

  function auto_insert() {
    let tot_amount_sum = $('#item_form')[0].tot_amount_sum.value;
    let cut_price = $('#item_form')[0].cut_price.value;

    tot_amount_sum = Fn.cfNumeric(tot_amount_sum);
    cut_price = Fn.cfNumeric(cut_price);

    //원 결제금액
    let won_price = eval(tot_amount_sum) - eval(cut_price);


    let use_point = $('#item_form')[0].use_point.value;
    let coupon_price = $('#item_form')[0].coupon_price.value;

    if (use_point) {
      use_point = Fn.cfNumeric(use_point);
    } else {
      use_point = 0;
    }

    if (coupon_price) {
      coupon_price = Fn.cfNumeric(coupon_price);
    } else {
      coupon_price = 0;
    }

    let new_price = eval(won_price) - eval(use_point) - eval(coupon_price);
    new_price = Fn.commaNum(new_price);

    $('#item_form')[0].unpaid_price.value = new_price;

    if ($('#item_form')[0].payCard.value) {
      $('#item_form')[0].pay_price.value = $('#item_form')[0].unpaid_price.value
      $('#item_form')[0].unpaid_price.value = 0;

      let dd = $('#item_form')[0].payCard.value.split(",");
      let dd_size = dd.length;

      $('#card_month')[0].innerHTML = "";
      if ($('#item_form')[0].payCard.value == "2222" || $('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제")	//카드결제
      {
        $('#item_form')[0].pay_price.readOnly = true;
        var price = Fn.cfNumeric($('#item_form')[0].pay_price.value);
        if (eval(price) >= 50000) {
          var hh = "<select name='month_card' id='month_card' class='form-select wid70' style='width:70px; display:inline-block;'>";
          hh += "<option value='00'>일시불</option>";
          hh += "<option value='02'>2개월</option>";
          hh += "<option value='03'>3개월</option>";
          hh += "<option value='04'>4개월</option>";
          hh += "<option value='05'>5개월</option>";
          hh += "<option value='06'>6개월</option>";
          hh += "<option value='07'>7개월</option>";
          hh += "<option value='08'>8개월</option>";
          hh += "<option value='09'>9개월</option>";
          hh += "<option value='10'>10개월</option>";
          hh += "<option value='11'>11개월</option>";
          hh += "<option value='12'>12개월</option>";
          hh += "<option value='18'>18개월</option>";
          hh += "<option value='24'>24개월</option>";
          hh += "</select>";

          $("#card_month").append(hh)
        } else {
          var hh = "<select name='month_card' id='month_card' class='form-select wid70' style='width:70px; display:inline-block;'>";
          hh += "<option value='00'>일시불</option>";
          hh += "</select>";

          $("#card_month").append(hh)
        }

        $('#cash_p_show')[0].style.display = "none";
      } else if ($('#item_form')[0].payCard.value == "1111" || dd_size == 3)	//현금 혹은 계좌이체
      {
        $('#item_form')[0].pay_price.readOnly = false;

        $('#card_month')[0].innerHTML = "";
        $('#cash_p_show')[0].style.display = "inline";
      } else															//카드 선택한경우
      {
        $('#item_form')[0].pay_price.readOnly = false;
        $('#card_month')[0].innerHTML = "";
        $('#item_form')[0].cash_p.checked = false;
        $('#cash_p_show')[0].style.display = "none";
      }
    } else {
      $('#card_month')[0].innerHTML = "";
      $('#item_form')[0].cash_p.checked = false;
      $('#cash_p_show')[0].style.display = "none";
    }

    if ($('#card_month')[0].innerHTML !== '' || $('#cash_p_show')[0].style.display === "inline")
      $('#payCard2').show();
    else
      $('#payCard2').hide();
  }

  function point_use() {
    var able_point = $('#item_form')[0].able_point.value;
    var use_point = $('#item_form')[0].use_point.value;

    console.log('use_point', use_point);
    able_point = Fn.cfNumeric(able_point);
    use_point = Fn.cfNumeric(use_point);

    let able_point_show = Fn.commaNum(able_point);

    if (eval(use_point) > eval(able_point)) {
      alert("사용가능한 마일리지는 " + able_point_show + " 입니다");
      $('#item_form')[0].use_point.value = 0;
    }


    use_p_chk();
  }

  function use_point_chk() {
    //지불수단 및 기타 초기화
    $('#item_form')[0].payCard.value = "";		//지불수단 초기화
    $('#item_form')[0].pay_price.value = "";		//받은돈 초기화
    $('#card_month')[0].innerHTML = "";	//할부개월 초기화


    var use_point = $('#item_form')[0].use_point.value;
    use_point = Fn.cfNumeric(use_point);
    use_point = Math.floor(use_point / 1000) * 1000;
    use_point = Fn.commaNum(use_point);
    $('#item_form')[0].use_point.value = use_point;


    //사용마일리지는 결제를 초과할수 없다
    var r_price = $('#item_form')[0].unpaid_price.value;
    r_price = Fn.cfNumeric(r_price);
    if (eval(r_price) < 0) {
      alert("마일리지 사용은 결제금액을 초과할수 없습니다");
      $('#item_form')[0].use_point.value = 0;
    }


    use_p_chk();
  }

  function chk_coupon(value) {
    //지불수단 및 기타 초기화
    $('#item_form')[0].payCard.value = "";		//지불수단 초기화
    $('#item_form')[0].pay_price.value = "";		//받은돈 초기화
    $('#card_month')[0].innerHTML = "";	//할부개월 초기화

    let won_data = value.trim();
    let data = won_data.split("||");

    let coupon_seq = data[0];
    let coupon_type = data[1];
    let coupon_price = data[2];

    if (won_data) {
      if (coupon_type == "B")	//금액쿠폰
      {
        $('#item_form')[0].coupon_price.value = eval(coupon_price);
        use_p_chk();
      } else {
        alert("기간은 서비스등록시 자동 합산처리됩니다");
        $('#item_form')[0].coupon_price.value = 0;
        use_p_chk();
      }
    } else {
      $('#item_form')[0].coupon_price.value = 0;
      use_p_chk();
    }
  }

  function use_p_chk() {
    //원 금액 = 결제금액 - 절사
    let tot_amount_sum = $('#item_form')[0].tot_amount_sum.value;
    let cut_price = $('#item_form')[0].cut_price.value;

    tot_amount_sum = Fn.cfNumeric(tot_amount_sum);
    cut_price = Fn.cfNumeric(cut_price);

    //원 결제금액
    let won_price = eval(tot_amount_sum) - eval(cut_price);

    let use_point = $('#item_form')[0].use_point.value;
    let coupon_price = $('#item_form')[0].coupon_price.value;

    use_point = Fn.cfNumeric(use_point);
    coupon_price = Fn.cfNumeric(coupon_price);

    let new_price = eval(won_price) - eval(use_point) - eval(coupon_price);
    new_price = Fn.commaNum(new_price);

    $('#item_form')[0].unpaid_price.value = new_price;
  }


  String.prototype.cut = function (start, len) {
    var str = this;
    var l = 0;
    for (var i = 0; i < str.length; i++) {

      l += (str.charCodeAt(i) > 128) ? 2 : 1;

      if (l > len) return str.substring(start, i);

    }
    return str;
  };

  function nds() {
    var d = new Date();
    var t_year = d.getFullYear();

    t_year = new String(t_year)

    var year = t_year.substr(2, 2);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();

    if (eval(month) < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (eval(day) < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    if (eval(hour) < 10) {
      hour = "0" + hour;
    } else {
      hour = hour;
    }

    if (eval(minute) < 10) {
      minute = "0" + minute;
    } else {
      minute = minute;
    }

    if (eval(second) < 10) {
      second = "0" + second;
    } else {
      second = second;
    }

    let new_pay_id = year + month + day + hour + minute + second;

    return new_pay_id;
  }

  function pre_go_submit() {
    if ($('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제") {
      if (!$('#pay_form')?.[0]?.edtCardNum?.value) {
        setIsCardReaderOpen(true);
        return;
      }
    }

    //service_ok.innerHTML = "<a class='btn btn-danger'>서비스 신청</a>";

    // var activeTab = $("ul#tab_menu li.active>a").attr("href");

    // TODO : 면밀히 체크 필요
    var url = "/member/member/service/etc/save";
    var currBrand = $(`input[name='tab_item']:checked`).attr("desc").substring(0, 12);

    if (currBrand === '#menu_basics') {
      url = "/member/member/service/save";
    }
    if (currBrand === '#menu_option') {
      url = "/member/member/service/etc/save";
    }
    if (currBrand === '#menu_pt') {
      url = "/member/member/service/pt/save";
    }

    // var new_activeTab = activeTab.substr(0, 12);
    // if (new_activeTab == "#menu_basics") //기본
    // {
    // } else if (activeTab == "#menu_option") //부가
    // {
    //   var url = "member_service_etc_save.php";
    // } else if (activeTab == "#menu_pt") //pt
    // {
    //   var url = "member_service_pt_save.php";
    // }

    if ($('*[id^=it_show]').length < 1 && $('*[id^=it_op_show]').length < 1 && $('*[id^=it_pt_show]').length < 1) {
      alert("등록된 서비스신청내역이 없습니다.");
      //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      return;
    }

    if ($('#item_form')[0].payCard.value == "") {
      alert('받은돈 지불수단을 선택해주세요');
      //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      $('#item_form')[0].payCard.focus();
      return;
    }

    //230106 조기창 수정
    if ($('#_reg_step option:selected').val() == "") {
      alert('접수자를 선택해주세요');
      //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      $('#item_form')[0].payCard.focus();
      return;
    }

    if (url.indexOf("member/service/save") !== -1) {
      if ($('#item_form')[0].period_kind[0].checked == false && $('#item_form')[0].period_kind[1].checked == false && $('#item_form')[0].period_kind[2].checked == false) {
        alert("기간을 설정해주세요");
        //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }

    //무상서비스일때 비고 입력 필수
    let dd = $('#item_form')[0].payCard.value.split(",");
    let dd_size = dd.length;	//dd_size

    let pay2 = dd[0];
    if (pay2 == "244") {
      if ($('#bgo').val() == "") {
        alert("무상서비스로 결제시 비고 입력은 필수사항 입니다");
        // service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }

    if (url.indexOf("member/service/pt/save") !== -1) {
      if ($('#item_form')[0].pt_visit_course.value == "") {
        alert("PT가입경로를 선택해주세요");
        //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }


    //미납금이 있는경우 경고창 처리
    var m_price = $('#item_form')[0].unpaid_price.value;
    if (eval(Fn.cfNumeric(m_price)) > 10) {
      alert('미납금이 존재합니다. 정상적인 서비스신청인지 금액을 확인해주세요');
    }


    if ($('#item_form')[0].payCard.value == "2222" || $('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제") {
      //카드결제일경우 금액 필수입력
      if ($('#item_form')[0].pay_price.value == "" || $('#item_form')[0].pay_price.value == "0") {
        alert("카드결제일경우 받은돈을 입력해주세요");
        //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }

      //카드결제 항목 초기화
      //edtPayID.value = "";		//전문관리번호
      $('#pay_form')[0].edtAmount.value = 0;		//전문관리번호
      $('#pay_form')[0].edtTax.value = 0;			//부가세
      $('#pay_form')[0].edtInstallment.value = "00";//할부개월

      //1.전문관리번호 입력하기
      //new_time = nds();
      //new_time  = new_time.trim();
      //edtPayID.value = new_time;

      //2.결제금액 입력
      var pay_price = $('#item_form')[0].pay_price.value;

      //3.단가/부가세 입력
      cal_price(pay_price);

      //4. 할부개월 입력하기
      $('#pay_form')[0].edtInstallment.value = $('#item_form')[0].month_card.value;

      //$("#radioAuthType1").prop('checked', true);   //라디오버튼 체크

      if ($('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제") {
        $("#authType").val("80990");		//앱카드결제
        $('#pay_form')[0].WCC.value = "Q";
      } else {
        $("#authType").val("1010");		//신용카드
        $('#pay_form')[0].WCC.value = " ";
      }

      writeTo();

    }//추가사항1
    else if ($('#item_form')[0].payCard.value == "1111" || dd_size == 3) {
      if (localStorage.getItem("USER.systemID") == "testjbj") {
        if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 8 || localStorage.getItem("USER.systemAccountYN") == "Y") {
        } else {
          if ($('#item_form')[0].cash_p.checked == false) {
            alert("현금영수증은 필수사항입니다.");
            return;
          }
        }
      }

      //카드결제일경우 금액 필수입력
      if (($('#item_form')[0].pay_price.value == "" || $('#item_form')[0].pay_price.value == "0") && $('#item_form')[0].cash_p.checked == true) {
        alert("현금결제일경우 받은돈을 입력해주세요");
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
        return;
      }

      // 2023.10.22 수정
      if ($('#item_form')[0].cash_p.checked == true && result?.bOptRow?.TID) {

        //카드결제 항목 초기화
        //edtPayID.value = "";		//전문관리번호
        $('#pay_form')[0].edtAmount.value = 0;		//전문관리번호
        $('#pay_form')[0].edtTax.value = 0;			//부가세
        $('#pay_form')[0].edtInstallment.value = "00";//할부개월

        //1.전문관리번호 입력하기
        //new_time = nds();
        //new_time  = new_time.trim();
        //edtPayID.value = new_time;

        //2.결제금액 입력
        var pay_price = $('#item_form')[0].pay_price.value;

        //3.단가/부가세 입력
        cal_price(pay_price);

        $("#authType").val("5010");		//현금영수증
        writeTo();
      } else {
        go_submit();
      }
    } else {
      go_submit();
    }

  }

  function go_submit() {
    //service_ok.innerHTML = "<a class='btn btn-danger'>서비스 신청</a>";

    // var activeTab = $("ul#tab_menu li.active>a").attr("href");

    // var new_activeTab = activeTab.substr(0, 12);

    let dd = $('#item_form')[0].payCard.value.split(",");
    let pay2 = dd[0];

    // TODO : 면밀히 체크 필요
    var url = "/member/member/service/save";
    var currBrand = $(`input[name='tab_item']:checked`).attr("desc").substring(0, 12);

    if (currBrand === '#menu_basics') {
      if (pay2 == "244")
        url = "/member/member/service/free/save";
      else
        url = "/member/member/service/save";
    }
    if (currBrand === '#menu_option') {
      if (pay2 == "244")
        url = "/member/member/service/etc/save/free";
      else
        url = "/member/member/service/etc/save";
      // url = "/member/member/service/etc/save";
    }
    if (currBrand === '#menu_pt') {
      url = "/member/member/service/pt/save";
    }
    console.log(currBrand, url);

    // if (new_activeTab == "#menu_basics")
    // {
    //   if (pay2 == "244") {
    //     url = "member_service_free_save.php";
    //   } else {
    //     url = "member_service_save.php";
    //   }
    // } else if (activeTab == "#menu_option")
    // {
    //   url = "member_service_etc_save.php";
    // } else if (activeTab == "#menu_pt")
    // {
    //   url = "member_service_pt_save.php";
    // }


    if ($('*[id^=it_show]').length < 1 && $('*[id^=it_op_show]').length < 1 && $('*[id^=it_pt_show]').length < 1) {
      alert("등록된 서비스신청내역이 없습니다.");
      //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      return;
    }

    if ($('#item_form')[0].payCard.value == "") {
      alert('받은돈 지불수단을 선택해주세요');
      //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      $('#item_form')[0].payCard.focus();
      return;
    }

    if (url.indexOf("member/service/save") !== -1) {
      if ($('#item_form')[0].period_kind[0].checked == false && $('#item_form')[0].period_kind[1].checked == false && $('#item_form')[0].period_kind[2].checked == false) {
        alert("기간을 설정해주세요");
        //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }

    //인증코드 복사
    $('#item_form')[0].auth_code.value = $('#auth_code').val();
    //접수자 복사
    $('#item_form')[0].reg_step.value = $('#_reg_step').val();
    //회원번호 복사
    $('#item_form')[0].mb_no.value = props.mbNo;

    //버튼 숨김//
    // service_ok.style.display = "none";
    // la_cl.style.display = "none";
    // la_cl2.style.display = "none";
    // service_ing.style.display = "inline";

    /*
    $('#item_form')[0].target = "common_i_member_data";
    $('#item_form')[0].action = url;
    $('#item_form')[0].method = "post";
    $('#item_form')[0].submit();
    */

    let params = "?";
    {
      let con = $('[id^=in_it_id]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_ids=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_ca_id]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_ca_ids=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_amount]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_amounts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_reason]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_reasons=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_period_txt]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_period_txts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_ca_id]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_ca_ids=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_id]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_ids=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_amount]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_amounts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_period_txt]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_period_txts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_suit]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_suits=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_locker]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_lockers=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_op_reason]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_op_reasons=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_r_pt]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_r_pts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_p_pt]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_p_pts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_pt_cnt]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_pt_cnts=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_pt_price]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_pt_prices=" + encodeURIComponent(con) + "&";
    }
    {
      let con = $('[id^=in_it_pt_amount]').map(function () {
        return ($(this).val() || '');
      }).get().join("^");
      params += "in_it_pt_amounts=" + encodeURIComponent(con);
    }

    var test = "N";

    if (test == "Y") {
      $('#item_form')[0].test_YN.value = "Y";
      $('#item_form')[0].action = url
      $('#item_form')[0].submit();
    } else {
      var form = $('#item_form')[0];
      var formData = new FormData(form);
      $.ajax({
        url: url + params,
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          data = data?.message?.trim();
          //			document.write(data); return;
          let split = data.split("||");
          let re_data = split[0];
          let mb_no = split[1];
          if (re_data == "ok") {
            alert('등록되었습니다');
            $('#item_form')[0].reset();

            props?.onCallback();
            props?.onClose();
            // member_form.reset();
            // service_state_form.reset();
            // service_request_form.reset();
            // delay_form.reset();
            // member_view(mb_no);
            // it_menu_close();
            // cash_p_show.style.display = "none";

            if (split[2] == "Y") {
              //영수증출력
              //pop_up('print_pay.html?base_seq='+split[3],'print_pay','300','150','no')
              //future_send_YN - 아래 주석처리


              //common_i.location.href='print_pay.html?base_seq='+split[3]

            }
          } else {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            //service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
            // parent.location.href = "member_view.html";
          }
        }
      });
    }
  }


  function cal_price(price) {
    var SAL_PRICE = 0;
    var SAL_VAT = 0;

    price = Fn.cfNumeric(price);

    let SAL_AMT = price; // 판매금액

    SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
    SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
    SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

    $('#pay_form')[0].edtAmount.value = SAL_PRICE;
    $('#pay_form')[0].edtTax.value = SAL_VAT;

    //임시설정
    //edtAmount.value = "1004";
    //edtTax.value = "0";;

  }

  function getTime_YYMMDDHHMMSS() {
    var today = new Date();

    var year = today.getFullYear()
    var month = ('0' + (today.getMonth() + 1)).slice(-2);
    var day = ('0' + today.getDate()).slice(-2);

    var hours = ('0' + today.getHours()).slice(-2);
    var minutes = ('0' + (today.getMinutes() + 1)).slice(-2);
    var seconds = ('0' + today.getSeconds()).slice(-2);

    var time = year + month + day + hours + minutes + seconds;

    return time.slice(-12);
  }

  function jsonInsert(json, field, value) {
    if (json.length == 0) {
      json = '{"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    } else {
      json = json.substr(0, json.length - 1) + ',"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    }
    return json;
  }

  function ShowLoadingImage() {
    //결제시 이미지 표시 - 미사용
    //document.getElementById('btn_send').disabled = true;
    //document.all.LoadingPageLayer.style.visibility = "visible";
    //document.all.LoadingImageLayer.style.visibility = "visible";
  }

  function HideLoadingImage() {
    //document.getElementById('btn_send').disabled = false;
    //document.all.LoadingPageLayer.style.visibility = "hidden";
    //document.all.LoadingImageLayer.style.visibility = "hidden";
  }


  var authType;
  var payType;

  function ParseHttpResponse(rtn)	//결제결과 파싱
  {
    // 스트링 utf-8 -> multibyte 변환 필요
    //console.log('응답전문 길이: ' + (String(rtn).indexOf(String.fromCharCode(13)) + 1));

    // json 전문
    if (String(rtn).substr(0, 1) == '{') {
      let rtn_ele_cr = rtn.replace(String.fromCharCode(0x0d), '');
      console.log(rtn_ele_cr);
      const obj = JSON.parse(rtn_ele_cr);


      if (obj.ERROR_CHECK_RESULT == 'S' && obj.PKTYPE == 'S' && obj.REPLY == '0000') {
        // 신용
        if (obj.MSGTYPE == '1010' || obj.MSGTYPE == '1050') {
          // 카카오페이 신용
          if (obj.ADDINFO2.slice(0, 3) == 'KKO') {
            $('#pay_form')[0].edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            $('#pay_form')[0].edtAuthNo.value = String(obj.MESSAGE).substr(0, 10);
            $('#pay_form')[0].edtAuthUniqueNo.value = '';
          }
          // SSG페이 신용
          else if (obj.ADDINFO2.slice(0, 3) == 'SSG') {
            $('#pay_form')[0].edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            $('#pay_form')[0].edtAuthNo.value = String(obj.MESSAGE).substr(0, 12) + fillSpace(3);
            $('#pay_form')[0].edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
          // 일반 신용
          else {
            $('#pay_form')[0].edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            $('#pay_form')[0].edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
            $('#pay_form')[0].edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
        }

        // 현금영수증
        else if (obj.MSGTYPE == '5010' || obj.MSGTYPE == '5050') {
          $('#pay_form')[0].edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
          $('#pay_form')[0].edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
          $('#pay_form')[0].edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
        }


        var a17 = obj.ACQUIERCD;
        var a4 = obj.TID;
        var a14 = obj.MERCHANTNO;
        var a21 = obj.TRACK2DATA;

        var d1 = String(obj.MESSAGE).substr(0, 12);
        var d2 = String(obj.MESSAGE).substr(12, 12);

        var a19 = obj.CARDKBN;

        var d3 = String(obj.MESSAGE).substr(24, 12);

        var a16 = obj.ISSUER;
        var a18 = obj.ACQUIER;


        var won_rtn = rtn;


        var purchase_code = a17;		//매입사코드
        var machine_no = a4;			//단말기번호
        var company_no = a14;			//가맹점번호
        var card_no = a21;				//카드번호
        var permit_no = d1;				//승인번호
        var permit_time = d2;			//승인일시
        var card_gubun = a19;	//카드구분
        var unique_no = d3;		//거래고유번호
        //발급사코드

        $('#item_form')[0].purchase_code.value = purchase_code;				//발급사코드
        $('#item_form')[0].machine_no.value = machine_no;					//단말기번호
        $('#item_form')[0].company_no.value = company_no;					//가맹점번호
        $('#item_form')[0].card_no.value = card_no;							//카드번호
        $('#item_form')[0].permit_no.value = permit_no;						//승인번호
        $('#item_form')[0].permit_time.value = permit_time;					//승인일시
        $('#item_form')[0].card_gubun.value = card_gubun;					//카드구분
        $('#item_form')[0].unique_no.value = unique_no;						//거래고유번호


        //영수증 출력
        if ($('#item_form')[0].payCard.value == "2222" || $('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제")	//카드결제
        {
          var month_card = $('#item_form')[0].month_card.value;
        } else {
          var month_card = "00";
        }

        var price = $('#item_form')[0].pay_price.value;

        // 2023.10.22 수정
        var c1 = result?.bOptRow?.branch_name;
        var c2 = result?.bInfoRow?.company_no;
        var c3 = "이규정";
        var c4 = result?.bInfoRow?.address;
        var c5 = result?.bInfoRow?.tel;

        var c6 = "";
        c6 = a21.substr(0, 4) + "-" + a21.substr(4, 4) + "-" + a21.substr(8, 4) + "-" + a21.substr(12, 4);

        if ($('#item_form')[0].payCard.value == "2222" || $('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제")	//카드결제
        {
          var c7 = a16.trim();
          var c8 = a18.trim();
        }

        var c9 = "";
        c9 = d2.substr(0, 2) + "/" + d2.substr(2, 2) + "/" + d2.substr(4, 2) + " " + d2.substr(6, 2) + ":" + d2.substr(8, 2) + ":" + d2.substr(10, 2);

        var c10 = "";
        if (a19 == "CK") {
          c10 = "신용승인("
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        } else {
          c10 = "체크승인)"
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        }

        if ($('#item_form')[0].payCard.value == "2222" || $('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제")	//카드결제
        {
          var c11 = d1.trim();
          var c12 = a4.trim();
          var c13 = a14.trim();
        }


        price = Fn.cfNumeric(price);

        if (price >= 50000) {
          var c17 = "Y";
        } else {
          var c17 = "N";
        }

        let SAL_AMT = price; // 판매금액

        let SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
        let SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
        SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

        var c14 = Fn.commaNum(SAL_PRICE);
        var c15 = Fn.commaNum(SAL_VAT);
        var c16 = Fn.commaNum(price);

        var receipt_info = c1 + "|" + c2 + "|" + c3 + "|" + c4 + "|" + c5 + "|" + c6 + "|" + c7 + "|" + c8 + "|" + c9 + "|" + c10 + "|" + c11 + "|" + c12 + "|" + c13 + "|" + c14 + "|" + c15 + "|" + c16 + "|" + c17;
        //영수증 출력 끝

        $('#item_form')[0].rtn.value = won_rtn;								//전문데이터
        $('#item_form')[0].receipt_info.value = receipt_info;				//영수증데이터

        /*
        if(confirm("영수증을 출력하시겠습니까?"))
        {
            $('#item_form')[0].print_YN.value = "Y";
            go_submit();
            //pop_up('print_pay.html?data='+receipt_info,'locker_select','300','150','no')
        }
        else
        {
            go_submit();
        }
        */


        //카드승인일때만 영수증 출력
        if ($('#item_form')[0].payCard.value == "2222" || $('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] == "앱카드결제") {
          $('#item_form')[0].print_YN.value = "Y";
        } else {
          var new_hp = obj.TRACK2DATA;

          $('#item_form')[0].cash_hp.value = new_hp;				//핸드폰번호
          $('#item_form')[0].print_YN.value = "N";
        }
        go_submit();
      } else {
        // if ($('#pay_form')[0].WCC.value == "Q") {
        //   $('#pay_form')[0].WCC.value = "B";
        //   SendPayment();
        //   return;
        // }

        //거래실패시 에러메시지 출력
        var dda = obj.ERROR_CHECK_MESSAGE;
        //alert(dda);
        alert(dda + "\r정상적으로 승인되지 않았습니다. 잠시후 다시 시도해주세요");
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      }
    }

    $('#pay_form')[0].taResponse.value += "\n";
    $('#pay_form')[0].taResponse.value += "===================================================\n";
    $('#pay_form')[0].taResponse.value += "응답 전문: " + String(rtn);
    $('#pay_form')[0].taResponse.value += "\n";
    $('#pay_form')[0].taResponse.value += "===================================================\n";
  }

  function httpRequest(api, data) {

    ShowLoadingImage();

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 0) {
        alert("단말기 연결이 실패하였습니다.");
      }
      if (this.readyState == XMLHttpRequest.DONE) {
        HideLoadingImage();
        if (this.status == 200) {
          var rtn = xmlHttp.responseText;
          console.log('recieve');

          ParseHttpResponse(rtn);
        }
      } else {
        console.log('waiting...');
      }
    };
    xmlHttp.ontimeout = function (e) {
      //console.log('Timeout!');
      alert("시간초과! 다시 시도해주세요");
      // location.href = "member_view.html"
    };
    //xmlHttp.timeout = 35 * 1000;

    let url = 'http';
    if (document.querySelector('input[name="HttpType"]:checked').value == 0)
      url += 's';
    url += '://127.0.0.1:';
    url += $('#pay_form')[0].portNo.value;
    url += api;
    xmlHttp.open("POST", url, true);
    //xmlHttp.open("POST", url.value, true);
    //xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=utf-8");
    //xmlHttp.setRequestHeader("Connection", "Close");	// 브라우저에 의존
    //xmlHttp.setRequestHeader("Content-Type", "text/plain");
    //xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    //xmlHttp.setRequestHeader("Content-Type", "application/json");

    xmlHttp.send(data);

    //console.log('send');		//요청전문
  }


  function writeTo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;

    switch (taskType) {
      // 거래요청
      case '0':
        SendPayment();
        break;

      // 기능요청
      case '1':
        //SendInfo();
        break;
    }
  }

  // 단말기 정보 요청
  function SendInfo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;
    var subCmd = document.querySelector('input[name="radioFunctinType"]:checked').value;
    var inputData = "";

    // 전문 요청
    $('#pay_form')[0].taResponse.value += "전문 요청" + "[업무구분:" + taskType + " 세부명령:" + subCmd + "]" + " [명령인자:" + String(inputData) + "]\n";
    $('#pay_form')[0].taResponse.value += "전문 전송 응답\n";
    //var rtn = objEncPCPOS.TPayPosAuthSerial(parseInt(taskType), parseInt(subCmd), String(inputData));
    var rtn = "TODO_ACTIVEX"

    // 전문 응답 처리
    $('#pay_form')[0].taResponse.value += "[ " + rtn + " ]\n";
    $('#pay_form')[0].taResponse.value += "단말기 시리얼번호 : " + rtn.substring(5, 15) + '\n';
  }

  var cardNum100;

  var amount12;
  var tax12;
  var tip12;
  var taxFree12;

  var amount9;
  var tax9;
  var tip9;
  var taxFree9;

  var recycleUse;
  var recycleRcdAmt9;

  function SendPayment() {
    // 가맹점 단말기 tid
    if ($('#pay_form')[0].txtTID.value.length != 10) {
      alert("단말기 TID 자리수 확인(10자리)");
      $('#pay_form')[0].txtTID.focus();
      return;
    }

    if ($('#pay_form')[0].edtCardNum.value.length > 0 && $('#pay_form')[0].edtCardNum.value.length < 10 || ($('#item_form')?.[0]?.payCard?.value?.split(",")?.[1] != "앱카드결제" && $('#pay_form')[0].edtCardNum.value.length > 98)) {
      alert("카드번호 자리수 확인(10자리 이상)");
      $('#pay_form')[0].edtCardNum.focus();
      return;
    }

    var cardPaddingLen;
    cardPaddingLen = 100 - $('#pay_form')[0].edtCardNum.value.length - 2;
    if ($('#pay_form')[0].WCC.value == 'K') {
      cardNum100 = $('#pay_form')[0].edtCardNum.value.length + $('#pay_form')[0].edtCardNum.value + fillSpace(cardPaddingLen);
    } else if ($('#pay_form')[0].WCC.value == 'A') {
      cardNum100 = $('#pay_form')[0].edtCardNum.value.length + $('#pay_form')[0].edtCardNum.value + fillSpace(cardPaddingLen);
    } else {
      cardNum100 = fillSpace(100);
    }

    // 거래금액 입력.
    if ($('#pay_form')[0].edtAmount.value.length > 9) {
      alert("거래금액 자리수 확인(9자리 이하)");
      $('#pay_form')[0].edtAmount.focus();
      return;
    }
    var amountLen = 9 - $('#pay_form')[0].edtAmount.value.length;
    amount9 = fillZero(amountLen) + $('#pay_form')[0].edtAmount.value;
    amount12 = '000' + amount9;

    // 세금 입력.
    if ($('#pay_form')[0].edtTax.value.length > 9) {
      alert("세금 자리수 확인(9자리 이하)");
      $('#pay_form')[0].edtTax.focus();
      return;
    }
    var taxLen = 9 - $('#pay_form')[0].edtTax.value.length;
    tax9 = fillZero(taxLen) + $('#pay_form')[0].edtTax.value;
    tax12 = '000' + tax9;

    // 봉사료 입력.
    if ($('#pay_form')[0].edtTip.value.length > 9) {
      alert("봉사료 자리수 확인(9자리 이하)");
      $('#pay_form')[0].edtTip.focus();
      return;
    }
    var tipLen = 9 - $('#pay_form')[0].edtTip.value.length;
    tip9 = fillZero(tipLen) + $('#pay_form')[0].edtTip.value;
    tip12 = '000' + tip9;

    // 비과세 입력.
    if ($('#pay_form')[0].edtTaxFree.value.length > 9) {
      alert("비과세 자리수 확인(9자리 이하)");
      $('#pay_form')[0].edtTaxFree.focus();
      return;
    }
    var taxFreeLen = 9 - $('#pay_form')[0].edtTaxFree.value.length;
    taxFree9 = fillZero(taxFreeLen) + $('#pay_form')[0].edtTaxFree.value;
    taxFree12 = '000' + taxFree9;

    recycleUse = document.querySelector('input[name="recycleType"]:checked').value;
    if (recycleUse == 1) {
      if ($('#pay_form')[0].recycleAmt.value.length > 9) {
        alert("비과세 자리수 확인(9자리 이하)");
        $('#pay_form')[0].recycleAmt.focus();
        return;
      }
      var recycleAmtLen = 9 - $('#pay_form')[0].recycleAmt.value.length;
      recycleRcdAmt9 = 'RCD' + fillZero(recycleAmtLen) + $('#pay_form')[0].recycleAmt.value;
    }

    var installment = "00"
    if ($('#pay_form')[0].edtInstallment.value.length != 2) {
      // 카드 결제 관련 입력값 검사.
      alert("할부 개월수 확인(00:일시불, 01~12:개월수");
      $('#pay_form')[0].edtInstallment.focus();
      return;
    }
    installment = $('#pay_form')[0].edtInstallment.value;

    // 거래요청 전문 생성
    authType = document.querySelector('#authType option:checked').value;
    payType = document.querySelector('#payType').value;


    // 취소인 경우 원 거래일시 / 원 거래번호 / 원거래고유번호 필요.
    if (authType == '1050' || authType == '1052' || authType == '1060' || authType == '5050') {
      // 원 거래일시 입력.
      if ($('#pay_form')[0].edtAuthDate.value.length != 6) {
        alert("원 거래일시 확인(6자리)");
        $('#pay_form')[0].edtAuthDate.focus();
        return;
      }
      //authDate = edtAuthDate.value; // 6자리 'YYMMDD'

      // 원 거래번호 입력.
      if ($('#pay_form')[0].edtAuthNo.value.length != 12) {
        alert("원 거래번호 확인(12자리 이하)");
        $('#pay_form')[0].edtAuthNo.focus();
        return;
      }
      //var authNoLen = 12 - edtAuthNo.value.length; // 12자리
      //authNo = edtAuthNo.value + fillSpace(authNoLen);

      // 원거래 고유번호 입력.
      if ($('#pay_form')[0].edtAuthUniqueNo.value.length != 12) {
        alert("원거래 고유번호 확인(12자리 이하)");
        $('#pay_form')[0].edtAuthUniqueNo.focus();
        return;
      }
      //var authUniqueNoLen = 12 - edtAuthUniqueNo.value.length;
      //authUniqueNo = edtAuthUniqueNo.value + fillSpace(authUniqueNoLen); // 12자리
    }

    let inputData = '';
    if (authType == '5010' || authType == '5050') // 현금 영수증 승인,취소
    {
      inputData = '';
      inputData += makeCash(authType);
    }
    if (authType == '1010' || authType == '1050' || authType == '1012' || authType == '1052') {
      inputData = '';
      inputData += makeCredit(authType);
    }
    // 앱카드결제 승인조회/취소조회
    else if (authType == '80990' || authType == '80991') {
      inputData = '';
      inputData += makeEasyPay(authType);
    }

    // 승인 요청 전문 출력
    $('#pay_form')[0].taResponse.value += '요청 전문: ' + String(inputData);

    // http 전송
    var api = '/tPayDaemon/Auth'
    httpRequest(api, inputData);

  }

  function nds() {
    var d = new Date();
    var t_year = d.getFullYear();

    t_year = new String(t_year)

    var year = t_year.substr(2, 2);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();

    if (eval(month) < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (eval(day) < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    if (eval(hour) < 10) {
      hour = "0" + hour;
    } else {
      hour = hour;
    }

    if (eval(minute) < 10) {
      minute = "0" + minute;
    } else {
      minute = minute;
    }

    if (eval(second) < 10) {
      second = "0" + second;
    } else {
      second = second;
    }

    let new_pay_id = year + month + day + hour + minute + second;

    return new_pay_id;
  }

  function makeCredit(authType) {
    let new_time = nds();
    new_time = new_time.trim();

    var packet;
    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));			//전문종류
    packet = jsonInsert(packet, 'TID', $('#pay_form')[0].txtTID.value);					//단말기번호
    packet = jsonInsert(packet, 'MSGNO', new_time);						//전문번호
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());	//전문생성일자

    if ($('#pay_form')[0].WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', $('#pay_form')[0].WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }


    packet = jsonInsert(packet, 'INSTALLMENT', $('#pay_form')[0].edtInstallment.value);
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);
    packet = jsonInsert(packet, 'CURRENCY', 'KRW');

    if (authType == '1010' || authType == '1012') {
      //packet = jsonInsert(packet, 'TRANSDATE', fillSpace(6));
      //packet = jsonInsert(packet, 'AUTHORITY', fillSpace(12));
      //packet = jsonInsert(packet, 'ORGAUTHNO', fillSpace(12));
    } else {
      packet = jsonInsert(packet, 'TRANSDATE', $('#pay_form')[0].edtAuthDate.value);
      packet = jsonInsert(packet, 'AUTHORITY', $('#pay_form')[0].edtAuthNo.value);
      //packet = jsonInsert(packet, 'ORGAUTHNO', $('#pay_form')[0].edtAuthUniqueNo.value);	// 직전취소 설정
    }

    packet = jsonInsert(packet, 'NOTAX', taxFree9);			//비과세금액
    /*
    packet = jsonInsert(packet, 'PINNO', fillSpace(18));		//비밀번호
    packet = jsonInsert(packet, 'OILINFO', fillSpace(24));	//주유정보
    packet = jsonInsert(packet, 'SITEBIZNO', fillSpace(10));	//하위 사업자 번호
    */
    packet = jsonInsert(packet, 'POSSERIALNO', 'JTPOSDM16011E278');	//pos 시리얼 번호

    //packet = jsonInsert(packet, 'ADDINFO1', fillSpace(32));			//부가정보
    //packet = jsonInsert(packet, 'ADDINFO2', fillSpace(128));			//부가정보2
    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', fillSpace(64) + recycleRcdAmt9 + fillSpace(52));			//부가정보2
    }
    //packet = jsonInsert(packet, 'RESERVED', fillSpace(64));			//reserved

    packet = jsonInsert(packet, 'SIGNKBN', ' ');				//서명 여부
    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));		// CR

    return packet;
  }

  function makeEasyPay(authType) {
    let new_time = nds();
    new_time = new_time.trim();

    var taxMediacodeLen = 3 - $('#pay_form')[0].edtMediaCode.value.length;
    var taxMediacode = fillSpace(taxMediacodeLen) + $('#pay_form')[0].edtMediaCode.value;

    var packet;

    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', '8099');
    packet = jsonInsert(packet, 'TID', $('#pay_form')[0].txtTID.value);
    packet = jsonInsert(packet, 'MSGNO', new_time); //전문관리번호 (POS가 거래를 확인 하기 위한 번호)
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());

    if ($('#pay_form')[0].WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', $('#pay_form')[0].WCC.value);

      var barcodeLen = $('#pay_form')[0].edtCardNum.value.length;
      var barcodeLenLen = String(barcodeLen).length;
      var barcode = fillZero(4 - barcodeLenLen) + barcodeLen;
      barcode += $('#pay_form')[0].edtCardNum.value + fillSpace(1024 - 4 - barcodeLen);
      packet = jsonInsert(packet, 'TRACK2DATA', barcode);
    }

    if (authType == '80990') {
      packet = jsonInsert(packet, 'TRADEKIND', '1010');
    } else if (authType == '80991') {
      packet = jsonInsert(packet, 'TRADEKIND', '1050');
    }

    packet = jsonInsert(packet, 'INSTALLMENT', $('#pay_form')[0].edtInstallment.value);

    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);

    packet = jsonInsert(packet, 'MEDIACODE', taxMediacode);	// 결제서비스 구분

    packet = jsonInsert(packet, 'CARDCONTAINIC', fillSpace(1));	// IC카드 포함 여부

    packet = jsonInsert(packet, 'CURRENCY', 'KRW');

    if (authType == '80990') {
      /*
        packet = jsonInsert(packet, 'AUTHORITY', fillSpace(15));
        packet = jsonInsert(packet, 'TRANSDATE', fillSpace(6));
        packet = jsonInsert(packet, 'VANUNIQUENO', fillSpace(12));
        packet = jsonInsert(packet, 'PAYKIND', fillSpace(1));
        */
    } else if (authType == '80991') {
      var authNoLen = 15 - $('#pay_form')[0].edtAuthNo.value.length; // 10자리
      var authNo = $('#pay_form')[0].edtAuthNo.value + fillSpace(authNoLen);
      packet = jsonInsert(packet, 'AUTHORITY', authNo);
      packet = jsonInsert(packet, 'TRANSDATE', $('#pay_form')[0].edtAuthDate.value.slice(0, 6));
      if ($('#pay_form')[0].edtAuthUniqueNo.value.length) {
        packet = jsonInsert(packet, 'VANUNIQUENO', $('#pay_form')[0].edtAuthUniqueNo.value);
      }
      packet = jsonInsert(packet, 'PAYKIND', fillSpace(1));
    }

    packet = jsonInsert(packet, 'NOTAX', taxFree9);
    /*
    packet = jsonInsert(packet, 'SITEBIZNO', fillSpace(10));
    */
    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'POSRESERVED', recycleRcdAmt9 + fillSpace(52));
    }
    /*
    packet = jsonInsert(packet, 'PRODUCTNM', fillSpace(50));
    packet = jsonInsert(packet, 'RESERVED1', fillSpace(78));
    packet = jsonInsert(packet, 'RESERVED2', fillSpace(128));
    packet = jsonInsert(packet, 'RESERVED3', fillSpace(256));
    packet = jsonInsert(packet, 'RESERVED4', fillSpace(256));
    */
    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));

    return packet;
  }

  function makeCash(authType) {
    var packet;

    let new_time = nds();
    new_time = new_time.trim();

    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));
    packet = jsonInsert(packet, 'TID', $('#pay_form')[0].txtTID.value); //TID
    packet = jsonInsert(packet, 'MSGNO', new_time);					//전문관리번호 (POS가 거래를 확인 하기 위한 번호)
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());		//전문 생성 일자

    if ($('#pay_form')[0].WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', $('#pay_form')[0].WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);

    if ($('#item_form')[0].cash_p_gubun.value == "0") {
      //inputData += "0";    //거래구분자 -개인
      packet = jsonInsert(packet, 'AUTH_TYPE', '0'); //개인
    } else {
      //inputData += "1";    //거래구분자 -사업자
      packet = jsonInsert(packet, 'AUTH_TYPE', '1'); //사업자
    }


    if (authType == '5010') {

    } else //취소 인 경우
    {
      packet = jsonInsert(packet, 'TRANSDATE', $('#pay_form')[0].edtAuthDate.value); //원거래일자
      packet = jsonInsert(packet, 'AUTHORITY', $('#pay_form')[0].edtAuthNo.value); //원승인번호
      packet = jsonInsert(packet, 'CANCELCODE', '1');
    }
    packet = jsonInsert(packet, 'NOTAX', taxFree9);

    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', recycleRcdAmt9 + fillSpace(20));
    }

    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));

    return packet;
  }


  function fillZero(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += '0';
    }

    return zeros;
  }

  function fillSpace(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += ' ';
    }

    return zeros;
  }

  function leadingZeros(n, digits) {
    var zero = '';
    n = n.toString();

    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
        zero += '0';
    }

    return zero + n;
  }

  const onTabSub = () => {
    var new_activeTab = $(`input[name='tab_item']:checked`).attr("desc").substring(0, 12);
    const item_form = $('#item_form')[0];

    $("#tr_pt_course").hide();

    if (new_activeTab == "#menu_basics") {
      //기본
      $("#tr_twice_daily_price").show();
      $("#tr_period_show").show();
      $("#tr_period_kind").show();
      $("#tr_it_start_date").show();
      $("#tr_it_end_date").show();
      //$("#coupon_show").show();
      //$("#mileage_show").show();

      if (item_form.f_coupon_show.value == "Y") {
        $('#coupon_show').show();
      } else {
        $('#coupon_show').hide();
      }

      if (item_form.f_mileage_show.value == "Y") {
        $('#mileage_show').show();
      } else {
        $('#mileage_show').hide();
      }
    } else if (new_activeTab == "#menu_option" || new_activeTab == "#menu_pt") {
      //부가 or pt
      $("#tr_twice_daily_price").hide();
      $("#tr_period_show").hide();
      $("#tr_period_kind").hide();
      $("#tr_it_start_date").hide();
      $("#tr_it_end_date").hide();
      //$("#coupon_show").hide();
      //$("#mileage_show").hide();
      if (new_activeTab == "#menu_pt") {
        $("#tr_pt_course").show();
      }

      //마일리지 및 쿠폰 감춤
      $('#coupon_show').hide();
      $('#mileage_show').hide();
    }

    $('#card_month')[0].innerHTML = "";


    $('.ca10').each(function () {
      $(this).removeClass('on');
    });
    $('.ca20').each(function () {
      $(this).removeClass('on');
    });
    $('.ca30').each(function () {
      $(this).removeClass('on');
      $(this).next().removeClass("on");

      $("#ca30_reason" + $(this).attr("id")).val("")
      $("#ca30_reason" + $(this).attr("id")).prop('disabled', false);
    });

    $('#1342000261').each(function () {
      $(this).removeClass('on');
      $(this).next().removeClass("on");
    });

    $("#ca20_1342000261_val").val("1")
    $("#ca20_1342000261_val").prop('disabled', false);


    $('#ptday').each(function () {
      $(this).removeClass('on');
      $(this).next().removeClass("on");
    });

    $("#ca20_ptday_val").val("1")
    $("#ca20_ptday_val").prop('disabled', false);


    $('.op_ca20').each(function () {
      $(this).removeClass('on');
    });
    $('.op_ca30').each(function () {
      $(this).removeClass('on');
      $(this).next().removeClass("on");

      $("#op_ca30_reason" + $(this).attr("id")).val("")
      $("#op_ca30_reason" + $(this).attr("id")).prop('disabled', false);
    });
    $('.it_etc').each(function () {
      $(this).removeClass('on');
    });

    $("#ca20_1342000261_val").val('1')

    $('*[id^=it_show]').remove();
    $('*[id^=it_in]').remove();

    $('*[id^=it_op_show]').remove();
    $('*[id^=it_op_in]').remove();

    $('*[id^=it_pt_show]').remove();
    $('*[id^=it_pt_in]').remove();

    $("#op_period_month").val('0');

    $("#pt_cnt").val('');
    $("#pt_price").val('');
    $("#r_pt").val('');
    $("#p_pt").val('');

    $("#tot_sum_show").html("0")
    $("#period_show").html("")

    var currTabVal = $(`input[name='tab_item']:checked`).val();

    item_form.reset();

    $('input:radio[name="tab_item"]').filter(`[value="${currTabVal}"]`).attr('checked', true).siblings().attr('checked', false);
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        setTabIdx(1);
        load();

        try {
          $('#item_form')[0].coupon_price.value = "0";
        } catch (err) {
        }

        // $('#mnpLocNum').val(props.mbInfo?.locker_no);
        // $('#mnpLocName').val(props.mbInfo?.locker_name);
        // $('#mnpLocPass').val(props.mbInfo?.locker_pass);

        $('input:radio[name="tab_item"]').filter(`[value="basics"]`).attr('checked', true).siblings().attr('checked', false);
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>

        <span style={{display: "none"}}>
          {/*2023.10.22 수정*/}
          <form name="pay_form" id="pay_form">

            {/*연결방식-ok : */}
            <input type="radio" name="HttpType" value="0" checked="checked"/>HTTPS(보안) 연결 / <input type="radio" name="HttpType" value="1"/>HTTP 연결

            {/*Port 번호-ok : */}
            <input id="portNo" name="portNo" style={{width: '60px;'}} type="text" value="6443"/>

            {/*단말기번호-ok : */}
            <input id="txtTID" name="txtTID" style={{width: '200px;'}} type="text" value={result?.bOptRow?.TID}/>

            {/*사업자번호-ok : */}
            <input id="txtBiz" name="txtBiz" style={{width: '200px;'}} type="text" value=""/>

            <input type="radio" name="radioTaskType" value="0" checked="checked"/>거래 요청-ok
            <select id="authType" style={{width: '200px;'}}>
              <option value="1010">01.신용 승인</option>
              <option value="1050">02.신용 취소</option>
              <option value="1012">03.은련 승인</option>
              <option value="1052">04.은련 취소</option>
              <option value="5010">05.현금영수증 승인</option>
              <option value="5050">06.현금영수증 취소</option>
              <option value="8040">07.카카오페이 승인조회</option>
              <option value="8041">08.카카오페이 취소조회</option>
              <option value="80990">09.앱카드결제 승인</option>
              <option value="80991">10.앱카드결제 취소</option>
              <option value="8020">11.L.PAY 승인</option>
              <option value="8021">12.L.PAY 취소</option>
              <option value="8080">13.현금IC 승인</option>
              <option value="8081">14.현금IC 취소</option>
              <option value="8084">15.현금IC 조회</option>
              <option value="8050">16.제로페이 승인</option>
              <option value="8051">17.제로페이 취소</option>
              <option value="1014A">18.ICQR 승인</option>
              <option value="1014C">19.ICQR 취소</option>
              <option value="8030">20.SSG페이 승인</option>
              <option value="8031">21.SSG페이 취소</option>
              <option value="8036">22.SSG페이 확정</option>
              <option value="8046">23.알리페이 승인</option>
              <option value="8047">24.알리페이 취소</option>
              <option value="6080">25.수표조회</option>
              <option value="3010">26.포인트적립 승인</option>
              <option value="4010">27.멤버쉽 승인</option>
            </select>

            <input type="radio" name="radioTaskType" value="1"/>기능 요청-ok
            <select id="abilityType" style={{width: '200px;'}}>
              <option value="PC">01.식별번호 요청</option>
              <option value="KC">02.키 교환 요청</option>
              <option value="DC">03.데몬 상태 체크</option>
              <option value="RC">04.키오스크 리더기 체크</option>
              <option value="UP">05.데몬 업데이트 요청</option>
              <option value="LG">06.로그 수집 요청</option>
            </select>

            {/*현금영수증 승인 구분-ok :*/}
            <select id="authCashType" name="authCashType" style={{width: '200px;'}}>
              <option value="0">01.소비자 소득공제</option>
              <option value="1">02.사업자 지출증빙</option>
            </select>

            {/*WCC-ok :*/}
            <select id="WCC" name="WCC" style={{width: '200px;'}}>
              <option value=" ">01. 리더기 처리</option>
              <option value="K">02. Key-In</option>
              <option value="Q">03. 앱카드</option>
              <option value="A">04. 앱카드KeyIn</option>
              <option value="B">05. 바코드</option>
            </select>

            {/*결제 서비스 코드*/}
            <input type="text" id="edtMediaCode" style={{width: '200px;'}}/>

            {/*지불수단-ok*/}
            <select id="payType" name="payType" style={{width: '200px;'}}>
              <option value="C">01. 신용</option>
              <option value="M">02. 머니</option>
              <option value="B">03. 계좌</option>
            </select>

            {/*카드번호-ok*/}
            <input type="text" id="edtCardNum" name="edtCardNum" value="" style={{width: '200px;'}}/>

            {/*거래금액-ok*/}
            <input type="text" id="edtAmount" name="edtAmount" value="0" style={{width: '200px;'}}/>

            {/*세금-ok*/}
            <input type="text" id="edtTax" name="edtTax" value="0" style={{width: '200px;'}}/>

            {/*봉사료-ok*/}
            <input type="text" id="edtTip" name="edtTip" value="0" style={{width: '200px;'}}/>

            {/*할부개월-ok*/}
            <input type="text" id="edtInstallment" name="edtInstallment" value="00" placeholder="00" style={{width: '200px;'}}/>

            {/*비과세 금액-ok*/}
            <input type="text" id="edtTaxFree" name="edtTaxFree" value="0" style={{width: '200px;'}}/>

            {/*자연순환보증금-ok*/}
            <input type="radio" name="recycleType" value="0" checked="checked"/>없음 <input type="radio" name="recycleType" value="1"/>있음 <input id="recycleAmt" style={{width: "80px"}} type="text" value="0"/>

            {/*원 거래일자-ok*/}
            <input type="text" id="edtAuthDate" name="edtAuthDate" placeholder="YYMMDD" style={{width: '200px;'}}/>

            {/*원 승인번호-ok*/}
            <input type="text" id="edtAuthNo" name="edtAuthNo" style={{width: '200px;'}}/>

            {/*원거래 고유번호-ok*/}
            <input type="text" id="edtAuthUniqueNo" name="edtAuthUniqueNo" style={{width: '200px;'}}/>

            <textarea id="taResponse" name="taResponse" style={{height: "260px", width: "100%;"}} rows="1" cols="129"></textarea>

          </form>
        </span>

        <form name="item_form" id="item_form">
          <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1650px', maxHeight: "1250px"}}>
            <div className="POP_title">
              <h5><strong>회원권판매</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100" style={{minHeight: "700px", maxHeight: "700px", overflowY: "scroll"}}>
              <div className="sales_wrap">
                <div style={{width: 'calc(98% - 320px)'}}>

                  <span id="NowBrand" style={{display: "none"}}>{localStorage.getItem("USER.systemBrand") || ''}</span>
                  <span id="NowBranch_code" style={{display: "none"}}>{localStorage.getItem("USER.systemBranchCode") || ''}</span>

                  <div className="tabs">
                    <input id="id01" type="radio" name="tab_item" value="basics" desc={`#menu_basics${localStorage.getItem("USER.systemBrand") || ''}`}
                           onClick={(e) => {
                             onTab(1);
                             // onTabSub();
                           }}
                    />
                    <label className="tab_item" htmlFor="id01">기본</label>

                    <input id="id02" type="radio" name="tab_item" value="option" desc="#menu_option"
                           onClick={(e) => {
                             onTab(2);
                             // setTimeout(() => {
                             //   $('#remain_period').prop("checked", true);
                             //   // onTabSub();
                             // }, 0)
                           }}
                    />
                    <label className="tab_item" htmlFor="id02">부가</label>

                    <input id="id03" type="radio" name="tab_item" value="pt" desc="#menu_pt"
                           onClick={(e) => {
                             onTab(3);
                             // onTabSub();
                           }}
                    />
                    <label className="tab_item" htmlFor="id03">PT</label>

                    {/*타브랜드 리스트*/}
                    {result?.etcBrandQry?.map((item, index) =>
                      <>
                        <input id={`id0${index + 4}`} type="radio" name="tab_item" value={item?.brand} desc={`#menu_basics${item?.brand}`}
                               onClick={(e) => {
                                 onTab(index + 4);
                                 // onTabSub();
                               }}
                        />
                        <label className="tab_item" htmlFor={`id0${index + 4}`}>{item?.brand_name}</label>
                      </>
                    )}
                  </div>

                  {/*----------------------------------------------------------------*/}
                  {/*                                                                */}
                  {/*                           기        본                         */}
                  {/*                                                                */}
                  {/*----------------------------------------------------------------*/}
                  {tabIdx === 1 &&
                    <>
                      <div className="my-3 py-3 search_box" style={{borderRadius: '8px'}}>
                        <div className="product">
                          {result?.bList1?.map((item, index) =>
                            <dd className="prd_1">
                              <span className="ca10" id={item?.it_id} dangerouslySetInnerHTML={{__html: item?.it_name?.replaceAll("*", "<br/>")}}></span>
                            </dd>
                          )}
                          {((localStorage.getItem("USER.systemBrand") || '') !== "P" && Fn.getCurrentDate() < "2024-02-01") &&
                            <>
                              <dd className="prd_1">
                                <span className="ca20" id="1342000261">일일이용</span><br/>
                                <span className="h6 bold700" style={{fontSize: "14px"}}>인원 : <input type="text" id="ca20_1342000261_val" className="cls-type-nubmer cls-num my-1"
                                                                                                    style={{maxHeight: "25px", lineHeight: "25px", maxWidth: '60px'}}/></span>
                              </dd>
                              <dd className="prd_1">
                                <span className="ca20" id="ptday">PT일일이용</span><br/>
                                <span className="h6 bold700" style={{fontSize: "14px"}}>인원 : <input type="text" id="ca20_ptday_val" className="cls-type-nubmer cls-num my-1" style={{maxHeight: "25px", lineHeight: "25px", maxWidth: '60px'}}/></span>
                              </dd>
                            </>
                          }
                        </div>
                      </div>

                      <div className="product2">
                        <dd className="prd_2"><span className="ca20" id="1341129358">운동복</span></dd>
                        <dd className="prd_2"><span className="ca20" id="1341129345">라커</span></dd>
                        {result?.bOptRow?.opt2 === "Y" &&
                          <dd className="prd_2"><span className="ca20" id="10">골프라커</span></dd>
                        }

                        <dd className="prd_2"><span className="it_etc" id={`it_twice_daily${localStorage.getItem("USER.systemBrand")}`}>1일 2회이용</span></dd>

                        {result?.cList1?.map((item, index) =>
                          <dd className="prd_2"><span className="ca20" id={item?.it_id} dangerouslySetInnerHTML={{__html: item?.it_name?.replaceAll("*", "<br/>")}}/></dd>
                        )}
                        {result?.buEtcNum > 0 &&
                          <dd className="prd_2"><span className="ca20" id="dis_10">부가세제외</span></dd>
                        }
                        {result?.halEtcNum > 0 &&
                          <dd className="prd_2"><span className="ca20" id="dis_5">10%할인</span></dd>
                        }
                      </div>

                      {Fn.getCurrentDate() < "2023-06-01" && result?.mList1?.length > 0 &&
                        <div className="my-3 py-3 search_box" style={{borderRadius: '8px'}}>
                          <div className="product">
                            {result?.mList1?.map((item, index) =>
                              <dd className="prd_3">
                                <span className="ca30" id={item?.it_id} dangerouslySetInnerHTML={{__html: item?.it_name?.replaceAll("*", "<br/>")}}></span>
                                <br/>
                                <span className="h6 bold700">사유 : <input type="text" className="my-1" style={{maxWidth: '100px'}} id={`ca30_reason${item?.it_id}`}/></span>
                              </dd>
                            )}
                          </div>
                        </div>
                      }
                    </>
                  }


                  {/*----------------------------------------------------------------*/}
                  {/*                                                                */}
                  {/*                           부        가                         */}
                  {/*                                                                */}
                  {/*----------------------------------------------------------------*/}
                  {tabIdx === 2 &&
                    <>
                      <div className="mt-4 d-flex">
                        <span className="h6 p-1">기간 : </span>
                        <select id="op_period_month" className="form-select">
                          <option value="0">선택</option>
                          {[...Array(12)].map((_, index) => {
                            const monthNumber = index + 1;
                            return (
                              <option value={monthNumber}>{monthNumber}개월</option>
                            );
                          })}
                        </select>
                        <input type="hidden" id="op_period_day" value='0'/>
                        <p className="p-1">남은기간 <input type="checkbox" id="remain_period"/></p>
                      </div>

                      <div className="product2">
                        <dd className="prd_2"><span className="op_ca20" id="1341129358">운동복</span></dd>
                        <dd className="prd_2"><span className="op_ca20" id="1341129345">라커</span></dd>
                        {result?.bOptRow?.opt2 === "Y" &&
                          <dd className="prd_2"><span className="op_ca20" id="10">골프라커</span></dd>
                        }
                      </div>

                      {Fn.getCurrentDate() < "2023-06-01" && result?.mList2?.length > 0 &&
                        <div className="my-3 py-3 search_box" style={{borderRadius: '8px'}}>
                          <div className="product">
                            {result?.mList2?.map((item, index) =>
                              <dd className="prd_3">
                                <span className="op_ca30" id={item?.it_id} dangerouslySetInnerHTML={{__html: item?.it_name?.replaceAll("*", "<br/>")}}></span>
                                <br/>
                                <span className="h6 bold700">사유 : <input type="text" className="my-1" style={{maxWidth: '100px'}} id={`op_ca30_reason${item?.it_id}`}/></span>
                              </dd>
                            )}
                          </div>
                        </div>
                      }
                    </>
                  }


                  {/*----------------------------------------------------------------*/}
                  {/*                                                                */}
                  {/*                           P         T                          */}
                  {/*                                                                */}
                  {/*----------------------------------------------------------------*/}
                  {tabIdx === 3 &&
                    <div className="mt-4 d-flex flex-wrap">
                      <table cellPadding={10}>
                        <tbody>
                        <tr style={{height: "40px"}}>
                          <td><span className="h6 p-3 bold700">PT횟수 : </span></td>
                          <td><input type="text" name="pt_cnt" id="pt_cnt" className="cls-type-number" style={{maxWidth: "150px"}}/></td>
                          <td><span className="h6 p-3 bold700">PT단가 : </span></td>
                          <td>
                            <input type="text" name="pt_price" id="pt_price"
                                   className="cls-type-fmtnum"
                                   onKeyUp={(e) => Fn.vComma(e.target)}
                            />
                          </td>
                        </tr>
                        <tr style={{height: "40px"}}>
                          <td><span className="h6 p-3 bold700">접수트레이너 : </span></td>
                          <td>
                            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} name="r_pt" id="r_pt">
                              <option value="" selected>::선택</option>
                              {result?.tnList1?.map((item, index) =>
                                <option value={item?.seq}>{item?.user_name}</option>
                              )}
                            </select>
                          </td>
                          <td><span className="h6 p-3 bold700">진행트레이너 : </span></td>
                          <td>
                            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} name="p_pt" id="p_pt">
                              <option value="" selected>::선택</option>
                              {result?.tnList2?.map((item, index) =>
                                <option value={item?.seq}>{item?.user_name}</option>
                              )}
                            </select>
                          </td>
                        </tr>
                        <tr style={{height: "40px"}}>
                          <td colSpan={4} className="text-center pt-3">
                            <button type="button" className="btn btn-sm btn-point px-3" id="it_pt_reg">PT 등록</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  }


                  {/*----------------------------------------------------------------*/}
                  {/*                                                                */}
                  {/*                           타 브랜드                            */}
                  {/*                                                                */}
                  {/*----------------------------------------------------------------*/}
                  {tabIdx > 3 && result?.etcBrandQry?.map((item, index) => {
                      if (tabIdx - 4 === index)
                        return (
                          <>
                            {item?.bList?.length > 0 &&
                              <div className="my-3 py-3 search_box" style={{borderRadius: '8px'}}>
                                <div className="product">
                                  {item?.bList?.map((row, index) =>
                                    <dd className="prd_1">
                                      <span className="ca10" id={row?.it_id} dangerouslySetInnerHTML={{__html: row?.it_name?.replaceAll("*", "<br/>")}}/>
                                    </dd>
                                  )}
                                </div>
                              </div>}

                            <div className="product2">
                              <dd className="prd_2"><span className="ca20" id="1341129358">운동복</span></dd>
                              <dd className="prd_2"><span className="ca20" id="1341129345">라커</span></dd>
                              {result?.bOptRow?.opt2 === "Y" &&
                                <dd className="prd_2"><span className="ca20" id="10">골프라커</span></dd>
                              }
                              <dd className="prd_2"><span className="ca20" id={`it_twice_daily${tabIdx - 3}`}>1일 2회 이용</span></dd>
                              {item?.cList?.map((row, index) =>
                                <dd className="prd_2"><span className="ca20" id={row?.it_id} dangerouslySetInnerHTML={{__html: row?.it_name?.replaceAll("*", "<br/>")}}/></dd>
                              )}
                              {result?.halEtcNum > 0 &&
                                <dd className="prd_2"><span className="ca20" id="dis_5">10%할인</span></dd>
                              }
                            </div>

                            {Fn.getCurrentDate() < "2023-06-01" && item?.mList?.length > 0 &&
                              <>
                                <div className="my-3 py-3 search_box" style={{borderRadius: '8px'}}>
                                  <div className="product">
                                    {item?.mList?.map((row, index) =>
                                      <dd className="prd_3">
                                        <span className="ca30" id={row?.it_id} dangerouslySetInnerHTML={{__html: row?.it_name?.replaceAll("*", "<br/>")}}></span>
                                        <br/>
                                        <span className="h6 bold700">사유 : <input type="text" className="my-1" style={{maxWidth: '100px'}} id={`ca30_reason${row?.it_id}`}/></span>
                                      </dd>
                                    )}
                                  </div>
                                </div>
                              </>
                            }
                          </>
                        )
                    }
                  )}
                </div>


                <div>
                  <input type="text" name="period_month" id="period_month"
                         style={{display: "none"}}/>
                  <input type="text" name="period_day" id="period_day"
                         style={{display: "none"}}/>
                  <input type="text" name="mb_no" style={{display: "none"}} value={props.mbNo}/>
                  <input type="text" name="reg_step" style={{display: "none"}}/>
                  <input type="text" name="auth_code" style={{display: "none"}}/>
                  <input type="text" name="test_YN" value="N" style={{display: "none"}}/>

                  <input type="text" name="purchase_code" style={{display: "none"}}/>
                  <input type="text" name="machine_no" style={{display: "none"}}/>
                  <input type="text" name="company_no" style={{display: "none"}}/>
                  <input type="text" name="card_no" style={{display: "none"}}/>
                  <input type="text" name="permit_no" style={{display: "none"}}/>
                  <input type="text" name="permit_time" style={{display: "none"}}/>
                  <input type="text" name="card_gubun" style={{display: "none"}}/>
                  <input type="text" name="unique_no" style={{display: "none"}}/>
                  <input type="text" name="rtn" style={{display: "none"}}/>
                  <input type="text" name="receipt_info" style={{display: "none"}}/>
                  <input type="text" name="print_YN" style={{display: "none"}}/>
                  <input type="text" name="cash_hp" style={{display: "none"}}/>

                  <input type="hidden" name="coupon_price"/>
                  <input type="hidden" name="f_coupon_show" value={props.coupons?.length > 0 ? "Y" : "N"}/>
                  <input type="hidden" name="f_mileage_show" value={props.mbInfo?.mb_point >= 1000 ? "Y" : "N"}/>
                  <input type="hidden" name="dis_10_YN"/>
                  <input type="hidden" name="dis_5_YN"/>

                  {/*TODO : DUMMY*/}
                  {/*<input type="hidden" name="coupon" id="coupon" value=""/>*/}
                  {/*<input type="hidden" name="use_point" id="use_point" value=""/>*/}


                  <p className="price_tit">서비스신청내역</p>
                  <div className="sevice_cart">
                    <table id="item_list_in"/>
                    <div className="my-3 search_box" id="item_list_show"/>

                    <dl key={'합계'}>
                      <dt>{'합계'}</dt>
                      <dd><input className="text-right" type="text" name="tot_amount" id="tot_amount" style={{width: '120px'}} readOnly={true}/></dd>
                    </dl>
                    <dl key={'1일2회(20%)'} id="tr_twice_daily_price">
                      <dt>{'1일2회(20%)'}</dt>
                      <dd><input className="text-right" type="text" name="twice_daily_price" id="twice_daily_price" style={{width: '120px'}} readOnly={true}/></dd>
                    </dl>
                    <dl key={'부가세'}>
                      <dt>{'부가세'}</dt>
                      <dd><input className="text-right" type="text" name="tot_tax" id="tot_tax" style={{width: '120px'}} readOnly={true}/></dd>
                    </dl>
                    <dl key={'결제금액'}>
                      <dt>{'결제금액'}</dt>
                      <dd><input className="text-right" type="text" name="tot_amount_sum" id="tot_amount_sum" style={{width: '120px'}} readOnly={true}/></dd>
                    </dl>
                    <dl key={'절사금액'}>
                      <dt>{'절사금액'}</dt>
                      <dd><input className="text-right" type="text" name="cut_price" id="cut_price" style={{width: '120px'}} readOnly={true}/></dd>
                    </dl>


                    <dl key={'미납금'}>
                      <dt>{'미납금'}</dt>
                      <dd><input className="text-right" type="text" name="unpaid_price" id="unpaid_price" style={{width: '120px'}}/></dd>
                    </dl>

                    <dl style={{display: result?.c?.length > 0 ? "flex" : "flex"}}>
                      <dt>쿠폰사용</dt>
                      <dd>
                        {props?.coupons?.length > 0 &&
                          <select name="coupon" className="form-select" style={{width: '120px'}} onChange={(e) => chk_coupon(e.target.value)}>
                            <option value="">사용가능 쿠폰 선택</option>
                            {props?.coupons?.map((coupon) => {
                              let couponValue = `${coupon.seq}||${coupon.coupon_type}||${coupon.coupon_price}`;
                              let couponText = coupon.coupon_name;

                              if (coupon.coupon_seq === "48" && coupon.coupon_price === "60000") {
                                couponValue = `48||${coupon.coupon_type}||66000`;
                                couponText = "코로나19 휴점기간 66,000원 3차 반짝 할인 쿠폰";
                              } else if (coupon.coupon_seq === "49" && coupon.coupon_price === "40000") {
                                couponValue = `49||${coupon.coupon_type}||44000`;
                                couponText = "코로나19 휴점기간 44,000원 3차 반짝 할인 쿠폰";
                              } else if (coupon.coupon_seq === "50" && coupon.coupon_price === "40000") {
                                couponValue = `50||${coupon.coupon_type}||44000`;
                                couponText = "코로나19 휴점기간 44,000원 3차 반짝 할인 쿠폰";
                              } else if (coupon.coupon_seq === "51" && coupon.coupon_price === "40000") {
                                couponValue = `51||${coupon.coupon_type}||44000`;
                                couponText = "코로나19 휴점기간 44,000원 3차 반짝 할인 쿠폰";
                              }

                              return (
                                <option key={coupon.seq} value={couponValue}>{couponText}</option>
                              );
                            })}
                          </select>
                        }
                        {props?.coupons?.length == 0 &&
                          <select name="coupon" className="form-select" style={{width: '120px'}}>
                            <option value=''>사용가능 쿠폰 선택</option>
                          </select>
                        }
                      </dd>
                    </dl>
                    <dl id="mileage_show" style={{display: props.mbInfo?.mb_point >= 1000 ? "flex" : "none"}}>
                      <dt>마일리지</dt>
                      <dd>
                        <span style={{fontSize: '12px', letterSpacing: '-1px'}}>사용가능</span>
                        :<span className="txt_red" style={{fontSize: '12px', letterSpacing: '-1px', color: "red"}}>{Fn.numberWithCommas(props.mbInfo?.mb_point)} P</span>

                        <input type="hidden" name="able_point" value={props.mbInfo?.mb_point}/>
                        <input
                          type="text"
                          name="use_point"
                          id="use_point"
                          className="form-control text-right"
                          style={{width: '120px'}}
                          onKeyUp={(e) => {
                            Fn.vComma(e.target);
                            point_use();
                          }}
                          onBlur={() => use_point_chk()}
                          onFocus={(e) => e.target.select()}
                        />
                      </dd>
                    </dl>

                    <dl>
                      <dt>받은돈</dt>
                      <dd>
                        <div className="input-group">
                          <select className="form-select" name="payCard" onChange={() => auto_insert()}>
                            <option value=''>지불수단</option>
                            <option value='1111'>현금</option>
                            <option value='2222'>카드</option>
                            {result?.pc1?.map((row, index) => <option value={`${row?.j_jukname == '앱카드결제' ? '2222' : row?.j_no},${row?.j_jukname},${row?.j_gyecode}`}>{row?.j_jukname}</option>)}
                            {result?.pc2?.map((row, index) => <option
                              value={`${row?.j_jukname == '앱카드결제' ? '2222' : row?.j_no},${row?.j_jukname}`}>{row?.j_jukname}{(row?.j_no == '174' || row?.j_no == '176' || row?.j_no == '180' || row?.j_no == '242' || row?.j_no == '244' || row?.j_gyecode == '1143') ? "" : "Card"}</option>)}
                          </select>
                          <input type="text" style={{width: '80px'}} name="pay_price" id="pay_price"/>
                        </div>
                      </dd>
                    </dl>
                    <dl id="payCard2" style={{display: "none"}}>
                      <dt></dt>
                      <dd>
                        <div id="cash_p_show" style={{float: "right", letterSpacing: "-1px", width: "auto", display: "none"}} className="input-group">
                          <span className="pr-1">현금영수증: <input type="checkbox" name="cash_p" value="Y"/></span>
                          <select name="cash_p_gubun" className="form-select wid80" style={{width: "74px"}}>
                            <option value='0'>개인</option>
                            <option value='1'>사업자</option>
                          </select>
                        </div>
                        <div className="input-group">
                          <span id="card_month"></span>
                        </div>
                      </dd>
                    </dl>
                    <dl id="tr_period_show">
                      <dt>기간</dt>
                      <dd><span id="period_show"></span></dd>
                    </dl>
                    <dl id="tr_period_kind">
                      <dt>설정</dt>
                      <dd className="text-right">
                        <div className="d-flex pt-1">
                          <div className="form-check form-check-inline" style={{marginRight: '8px'}} key={1}>
                            <input className="form-check-input period_kind" type="radio" name="period_kind" id={`period_kind1`} value={`1`}/>
                            <label className="form-check-label" htmlFor={`period_kind1`} style={{padding: '0px'}}>미정</label>
                          </div>
                          <div className="form-check form-check-inline" style={{marginRight: '8px'}} key={2}>
                            <input className="form-check-input period_kind" type="radio" name="period_kind" id={`period_kind2`} value={`2`}/>
                            <label className="form-check-label" htmlFor={`period_kind2`} style={{padding: '0px'}}>재등록</label>
                          </div>
                          <div className="form-check form-check-inline" style={{marginRight: '8px'}} key={1}>
                            <input className="form-check-input period_kind" type="radio" name="period_kind" id={`period_kind3`} value={`3`}/>
                            <label className="form-check-label" htmlFor={`period_kind3`} style={{padding: '0px'}}>신규</label>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <dl id="tr_it_start_date">
                      <dt>시작일</dt>
                      <dd><JDatePicker type="date" style={{width: '120px'}} name="it_start_date" id="it_start_date" onChange={date => {
                        it_period_chk();
                      }}/></dd>
                    </dl>
                    <dl id="tr_it_end_date">
                      <dt>종료일</dt>
                      <dd>
                        <span id="it_end_date_show" style={{display: "none"}}></span>
                        <JDatePicker type="date" style={{width: '120px'}} name="it_end_date" id="it_end_date"/>
                      </dd>
                    </dl>
                    <dl>
                      <dt>비고</dt>
                      <dd><input type="text" name="bgo" id="bgo"/></dd>
                    </dl>
                    <dl id="tr_pt_course" style={{display: "none"}}>
                      <dt>가입경로</dt>
                      <dd>
                        <div className="input-group">
                          <select className="form-select" name="pt_visit_course">
                            <option value=''>::선택</option>
                            {result?.ptVisitCourseQry?.map((row, index) => <option value={`${row?.sub_code_name}`}>{row?.sub_code_name}</option>)}
                          </select>
                        </div>
                      </dd>
                    </dl>
                    <div className="text-center mt-1">
                      <input type="checkbox" name="suit" id="suit" value='Y'/><span className="pl-1" style={{marginRight: "10px"}}>운동복</span>
                      <input type="checkbox" name="locker" id="locker" value='Y'/><span className="pl-1">라커</span>

                      <input type="text" name="locker_n" id="locker_n" style={{display: "none"}}/>
                      <input type="text" name="locker_g" id="locker_g" style={{display: "none"}}/>

                      <div style={{paddingTop: "5px", display: "none"}} id="it_locker_name">
                        라커선택:
                        <input
                          type="text"
                          name="mnpLocName"
                          id="mnpLocName"
                          style={{width: "100px"}}
                          readOnly
                          onClick={() => setIsLockerOpen(true)}
                        />
                        <div style={{paddingTop: "5px", display: "none"}} id="it_locker_id"><input type="text" name="mnpLocNum" id="mnpLocNum" style={{display: "none"}}/></div>
                      </div>

                      <div style={{paddingTop: "5px", display: "none"}} id="it_locker_pass">
                        라커비번:
                        <input
                          type="text"
                          name="mnpLocPass"
                          id="mnpLocPass"
                          style={{width: "100px"}}
                        />
                      </div>

                      {/*<div style={{paddingTop: "5px", display: "none"}} id="it_locker_id">*/}
                      {/*  <input*/}
                      {/*    type="text"*/}
                      {/*    name="mnpLocNum"*/}
                      {/*    id="mnpLocNum"*/}
                      {/*    style={{display: "none"}}*/}
                      {/*  />*/}
                      {/*</div>*/}
                    </div>
                    <button type="button" className="btn btn-point w-100 my-3" onClick={() => pre_go_submit()}>서비스신청</button>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <SelectLocker isOpen={isLockerOpen} onClose={() => setIsLockerOpen(false)} mbNo={props.mbNo} formType={"item_form"}/>
        </form>
      </div>

      <AppCardReader isOpen={isCardReaderOpen} onClose={() => setIsCardReaderOpen(false)} onCallback={() => pre_go_submit()}/>
    </Modal>
  );
};

export default MvMembership;
